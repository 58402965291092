import { Flex, Text } from '@chakra-ui/react';
import { CalibrationFlow } from 'libs/exo-session-manager/core';
import { CalibrationFlowBackButton } from 'views/+patientId/training/+trainingId/_components/CalibrationFlowBackButton';
import { CalibrationFlowNextButton } from 'views/+patientId/training/+trainingId/_components/CalibrationFlowNextButton';

import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

export const CalibrationFlowHeader = (props: {
  flow: CalibrationFlow;
  title: string;
  minRequiredChannels?: number;
  requiredPelvicChannel?: boolean;
}) => {
  const { flow, title, minRequiredChannels = 0, requiredPelvicChannel } = props;
  if (minRequiredChannels > 1 && requiredPelvicChannel) {
    // This case does not currently exist i left this error to handle it in the future.
    throw new Error('CalibrationFlowHeader error.');
  }
  return (
    <Flex alignSelf="stretch" justify="space-between">
      <Flex direction="column" gap={6}>
        <MainHeading text={title} variant="subheading48" data-testid="training.settings.heading" />
        {minRequiredChannels ? (
          <TranslateText
            text={`calibrationFlow.headers.${
              requiredPelvicChannel ? 'minRequiredPelvicChannels' : 'minRequiredChannels'
            }`}
            variant="openSans36"
            fontWeight="normal"
            data-testid="training.settings.subheading"
          >
            <Text as="span" fontWeight="bold" px={2}>
              {minRequiredChannels}
            </Text>
          </TranslateText>
        ) : null}
      </Flex>
      {flow ? (
        <Flex gap={{ base: 10, '2xl': 16 }}>
          <CalibrationFlowBackButton flow={flow} />
          <CalibrationFlowNextButton flow={flow} validationPassed={false} />
        </Flex>
      ) : null}
    </Flex>
  );
};
