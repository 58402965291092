import { ExoSessionError } from '@egzotech/exo-session/dist/src/ExoSessionError';
import { ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { Logger } from '@egzotech/universal-logger-js';
import {
  ExerciseTimelineEntryCAMParameterChange,
  ExerciseTimelineEntryCPMParameterChange,
  ExerciseTimelineEntryEmgParameterChange,
  ExerciseTimelineEntryEmsParameterChange,
  ExerciseTimelineEntryForceChange,
  ExerciseTimelineEntryGameParameterChange,
} from 'slices/trainingReportSlice';

import { FinishedReason } from '../exercises/FinishedReason';
import { GeneratedExerciseDefinition } from '../types/GeneratedExerciseDefinition';

import { ExtensionType } from './DeviceManager';

export type HistoryActionCategoryMap = {
  alerts:
    | 'cable-changed'
    | 'cable-detached'
    | 'cable-attached'
    | 'session-error'
    | 'channel-quality-change'
    | 'extension-changed'
    | 'extension-attached'
    | 'extension-detached'
    | 'spasticism-active'
    | 'spasticism-inactive';
  activity: 'play' | 'pause' | 'stop';
  'parameter-adjustment':
    | `${'cpm' | 'ems' | 'emg' | 'cam' | 'game'}-${string}-change`
    | 'initial-program-settings'
    | 'program-settings-update';
  'force-threshold-change': 'increase-force-threshold' | 'decrease-force-threshold';
};

export type HistoryActionParamsMap = {
  [K in HistoryActionCategoryMap[keyof HistoryActionCategoryMap]]: K extends
    | 'cable-changed'
    | 'cable-detached'
    | 'cable-attached'
    ? { cable: { id: number; description: string } }
    : K extends 'extension-changed' | 'extension-attached' | 'extension-detached'
    ? { extension: { type: ExtensionType } }
    : K extends 'session-error'
    ? { error: ExoSessionError }
    : K extends 'channel-quality-change'
    ? { channels: Record<number, ChannelConnectionQuality> }
    : K extends 'initial-program-settings' | 'program-settings-update'
    ? { definition: GeneratedExerciseDefinition }
    : K extends 'stop'
    ? { reason: FinishedReason }
    : K extends `cpm-${string}-change`
    ? ExerciseTimelineEntryCPMParameterChange
    : K extends `cam-${string}-change`
    ? ExerciseTimelineEntryCAMParameterChange
    : K extends `ems-${string}-change`
    ? ExerciseTimelineEntryEmsParameterChange
    : K extends `emg-${string}-change`
    ? ExerciseTimelineEntryEmgParameterChange
    : K extends `game-${string}-change`
    ? ExerciseTimelineEntryGameParameterChange
    : K extends `increase-force-threshold` | `decrease-force-threshold`
    ? ExerciseTimelineEntryForceChange
    : never;
};

export type HistoryAction = {
  [K in keyof HistoryActionCategoryMap]: {
    category: K;
    name: HistoryActionCategoryMap[K];
    timestamp: Date;
    actionNumber: number;
    params?: HistoryActionParamsMap[HistoryActionCategoryMap[K]];
  };
}[keyof HistoryActionCategoryMap];

type DisabledHistoryActionMap = {
  [key in keyof HistoryActionCategoryMap]?: {
    [name in HistoryActionCategoryMap[key]]?: boolean;
  };
};

export class HistoryActionTracker {
  protected _actions: HistoryAction[] = [];
  private disabledActions: DisabledHistoryActionMap = {};

  readonly logger = Logger.getInstance('HistoryActionTracker');

  get actions() {
    return this._actions;
  }

  add(
    category: 'alerts',
    name: HistoryActionCategoryMap['alerts'],
    params?: HistoryActionParamsMap[HistoryActionCategoryMap['alerts']],
  ): void;
  add(
    category: 'activity',
    name: HistoryActionCategoryMap['activity'],
    params?: HistoryActionParamsMap[HistoryActionCategoryMap['activity']],
  ): void;
  add(
    category: 'parameter-adjustment',
    name: HistoryActionCategoryMap['parameter-adjustment'],
    params: HistoryActionParamsMap[HistoryActionCategoryMap['parameter-adjustment']],
  ): void;
  add(
    category: 'force-threshold-change',
    name: HistoryActionCategoryMap['force-threshold-change'],
    params: Omit<
      HistoryActionParamsMap[HistoryActionCategoryMap['force-threshold-change']],
      'paramDescription' | 'unit' | 'type'
    >,
  ): void;
  add<TCategory extends keyof HistoryActionCategoryMap, TParams extends object>(
    category: TCategory,
    name: HistoryActionCategoryMap[TCategory],
    params?: TParams,
  ): void {
    if (this.disabledActions[category]?.[name]) {
      return;
    }

    const timestamp = new Date();
    const actionNumber = this._actions.filter(action => action.name === name).length + 1;
    this._actions.push({ name, timestamp, category, actionNumber, params } as HistoryAction);
    this.logger.info(
      'add',
      `Action '${name}' occurred at ${timestamp} ${params ? 'with given params: ' : '.'}`,
      params,
    );
  }

  disableAction<T extends keyof HistoryActionCategoryMap>(category: T, name: HistoryActionCategoryMap[T]) {
    let disabledCategory = this.disabledActions[category];

    if (!disabledCategory) {
      disabledCategory = this.disabledActions[category] = {};
    }

    disabledCategory[name] = true;
  }

  enableAction<T extends keyof HistoryActionCategoryMap>(category: T, name: HistoryActionCategoryMap[T]) {
    let disabledCategory = this.disabledActions[category];

    if (!disabledCategory) {
      disabledCategory = this.disabledActions[category] = {};
    }

    disabledCategory[name] = false;
  }

  getActionsInTimeRange(startTime: Date, endTime: Date) {
    return this._actions.filter(action => action.timestamp >= startTime && action.timestamp <= endTime);
  }

  getCategorizedActions =
    (category: HistoryAction['category']) =>
    (actions = this._actions) => {
      const categorizedActions: { count: number; actions: HistoryAction[] } = { count: 0, actions: [] };

      for (const action of actions) {
        if (action.category?.includes(category)) {
          categorizedActions.count++;
          categorizedActions.actions.push(action);
        }
      }

      return categorizedActions;
    };

  /**
   * Returns the time between two actions.
   *
   * If there is more than one action for given parameter time is calculated between actions of the same `actionNumber` property.
   *
   *  If `actionNumberSequence` in optional `options` parameter is set, then time is calculated based on the given sequence, ex. [1,2] - `actionNumber` 1 of `firstAction` is compared with `actionNumber` 2 of `secondAction`.
   *
   *  If `withIncrementedSecondActionNumber` in optional `options` parameter is set, then actions are compared with incremented second action `actionNumber` - ex. `actionNumber` 1 of `firstAction` is compared with `actionNumber` 2 of `secondAction`, `actionNumber` 2 of `firstAction` is compared with `actionNumber` 3 of `secondAction`, etc.
   */
  getTimeBetweenTwoActions =
    (
      firstAction: HistoryAction['name'],
      secondAction: HistoryAction['name'],
      options?: { actionNumberSequence?: [number, number]; withIncrementedSecondActionNumber?: number },
    ) =>
    (actions = this._actions) => {
      const firstActions = actions.filter(action => action.name === firstAction);
      const secondActions = actions.filter(action => action.name === secondAction);

      const timeBetweenActions: Record<number, number> = {};

      if (options?.actionNumberSequence) {
        const firstAction = firstActions
          .find(action => action.actionNumber === options.actionNumberSequence?.[0])
          ?.timestamp.getTime();
        const secondAction = secondActions
          .find(action => action.actionNumber === options.actionNumberSequence?.[1])
          ?.timestamp.getTime();

        if (firstAction && secondAction) {
          timeBetweenActions[1] = Math.abs(firstAction - secondAction);
        }

        return timeBetweenActions;
      }

      if (options?.withIncrementedSecondActionNumber) {
        for (let i = 0; i < secondActions.length; i++) {
          const firstAction = firstActions.find(action => action.actionNumber === i + 1)?.timestamp.getTime();
          const secondAction = secondActions
            .find(action => action.actionNumber === i + 1 + options.withIncrementedSecondActionNumber!)
            ?.timestamp.getTime();

          if (firstAction && secondAction) {
            timeBetweenActions[i + 1] = Math.abs(
              firstActions[i].timestamp.getTime() -
                secondActions[i + options.withIncrementedSecondActionNumber].timestamp.getTime(),
            );
          }
        }

        return timeBetweenActions;
      }

      for (let i = 0; i < Math.min(firstActions.length, secondActions.length); i++) {
        timeBetweenActions[i + 1] = Math.abs(
          firstActions[i].timestamp.getTime() - secondActions[i].timestamp.getTime(),
        );
      }

      return timeBetweenActions;
    };

  protected clear() {
    this._actions = [];
  }

  clearActionsInTimeRange(startTime: Date, endTime: Date) {
    const startIndex = this._actions.findIndex(action => action.timestamp.getTime() >= startTime.getTime());
    let endIndex = -1;

    for (let i = this._actions.length - 1; i >= 0; i--) {
      if (this._actions[i].timestamp.getTime() <= endTime.getTime()) {
        endIndex = i;
        break;
      }
    }

    if (startIndex === -1 || endIndex === -1) {
      this.logger.debug('clearActionsInTimeRange', `No actions found in given time range: ${startTime} - ${endTime}`);
      return [];
    }
    return this._actions.splice(startIndex, endIndex - startIndex + 1);
  }
}
