import { useEffect, useRef } from 'react';
import { Center, Container, HStack, Image, ModalBody, ModalContent, Text, VStack } from '@chakra-ui/react';
import ExtensionsImg from 'assets/images/update/Extensions.svg';
import PenDriveImg from 'assets/images/update/PenDrive.svg';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { convertVersionToString, useFirmwareVersion } from 'hooks/useFirmwareVersion';
import { useDevice } from 'libs/exo-session-manager/react';
import {
  AppSource,
  AppVersion,
  checkUpdateStatus,
  initializeRestart,
  initializeUpdate,
  setUpdateFirmwareStep,
  setUpdateSoftwareStep,
} from 'slices/updateSlice';

import { MainButton } from 'components/buttons/MainButton';
import StrippedProgressBar from 'components/common/StrippedProgressBar';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

const QUERY_INTERVAL = 500;

const ModalStack = ({ children }: { children: React.ReactNode }) => {
  return (
    <VStack gap={{ base: '6', '2xl': '8' }} justifyContent="space-between" py={4}>
      {children}
    </VStack>
  );
};

const UpdateHeader = ({ type }: { type?: AppSource }) => {
  const text =
    type === 'firmware'
      ? 'update.firmwareUpdate'
      : type === 'local'
      ? 'update.penDriveDetected'
      : 'update.systemUpdate';

  return (
    <Center>
      <MainHeading
        variant="heading"
        fontWeight="600"
        pb={0}
        text={text}
        textAlign="center"
        data-testid="update-header"
      />
    </Center>
  );
};

interface ExtensionsCheckProps {
  onUpdate: () => void;
  onCancel: () => void;
}

export const useCheckDeviceModulesPresence = () => {
  const { selectedDevice, firmwareModules } = useDevice();

  if ((selectedDevice && ['sidra-leg', 'stella-bio'].includes(selectedDevice?.type)) || !selectedDevice) {
    return true;
  }

  return (
    firmwareModules['motor:ankle'] &&
    firmwareModules.remote &&
    !firmwareModules['motor:ankle'].isMissing &&
    !firmwareModules.remote.isMissing
  );
};

const ExtensionsCheckStep = ({ onUpdate, onCancel }: ExtensionsCheckProps) => {
  const areModulesPresent = useCheckDeviceModulesPresence();

  return (
    <ModalStack>
      <UpdateHeader type="remote" />
      <Image src={ExtensionsImg} alt="extensions" />
      <VStack>
        <TranslateText variant="openSans24">
          <TranslateText as="span" text="update.extensionsCheck1" />
          <TranslateText as="span" text="update.extensionsCheck2" color="egzotechPrimaryColor" fontWeight="700" />
          <TranslateText as="span" text="update.extensionsCheck3" />
          <TranslateText as="span" text="update.extensionsCheck4" color="egzotechPrimaryColor" fontWeight="700" />
          <TranslateText as="span" text="update.extensionsCheck5" />
        </TranslateText>
      </VStack>
      <HStack w="full" justifyContent="space-between" px="8">
        <MainButton
          text="update.cancel"
          variant="mdOutlinedPrimaryButton"
          onClick={onCancel}
          minW="32"
          data-testid="update-cancel-button"
        />
        <MainButton
          text="update.update"
          variant="mdPrimaryButton"
          onClick={onUpdate}
          minW="32"
          data-testid="update-update-button"
          isDisabled={!areModulesPresent}
        />
      </HStack>
    </ModalStack>
  );
};
interface QuestionProps {
  version: AppVersion;
  source: AppSource;
  onUpdate: () => void;
  onCancel: () => void;
}

const QuestionStep = ({ version, source, onUpdate, onCancel }: QuestionProps) => {
  return (
    <ModalStack>
      <UpdateHeader type={source} />
      {source === 'remote' ? (
        <VStack gap="0" data-testid="update-content">
          <TranslateText variant="openSans24">
            <TranslateText as="span" text="update.updateQuestion1" />
            <Text as="span" fontWeight="bold">
              {version}
            </Text>
            <TranslateText as="span" text="update.updateQuestion2" />
          </TranslateText>
          <TranslateText variant="openSans24">
            <TranslateText as="span" text="update.updateStatement1" />
            <TranslateText as="span" fontWeight="bold" text="update.updateStatement2" />
            <TranslateText as="span" text="update.updateStatement3" />
          </TranslateText>
        </VStack>
      ) : source === 'local' ? (
        <>
          <Image src={PenDriveImg} alt="pendrive" data-testid="update-image" w={{ base: '48', '2xl': '64' }} />
          <VStack gap="0" data-testid="update-content">
            <TranslateText variant="openSans24">
              <TranslateText as="span" text="update.penDriveQuestion1" />
              <TranslateText as="span" fontWeight="bold">
                {version}
              </TranslateText>
            </TranslateText>
          </VStack>
          <TranslateText variant="openSans24" text="update.penDriveQuestion2" />
        </>
      ) : (
        <>
          <TranslateText variant="openSans24" w="md" overflowWrap="break-word" text="update.firmwareQuestion" />
          <TranslateText variant="openSans24" w="md" fontWeight="bold" whiteSpace="pre">
            {version}
          </TranslateText>
          <TranslateText variant="openSans24" w="md" text="update.penDriveQuestion2" />
        </>
      )}
      <HStack w="full" justifyContent="space-between" px="8">
        <MainButton
          text="update.cancel"
          variant="mdOutlinedPrimaryButton"
          onClick={onCancel}
          minW="32"
          data-testid="update-cancel-button"
        />
        <MainButton
          text="update.update"
          variant="mdPrimaryButton"
          onClick={onUpdate}
          minW="32"
          data-testid="update-update-button"
        />
      </HStack>
    </ModalStack>
  );
};

const ProgressStep = ({ progress, source }: { progress: number; source: AppSource }) => {
  return (
    <ModalStack>
      <UpdateHeader type={source} />

      <VStack w="full" data-testid="update-content">
        <HStack w="full" justifyContent="space-between">
          <TranslateText variant="openSans24" text="update.downloading" />
          <Text variant="openSans24">{Math.round(progress)}%</Text>
        </HStack>

        <StrippedProgressBar value={progress} />
      </VStack>
      <MainButton text="update.inProgress" variant="mdPrimaryButton" isDisabled={true} minW="32" />
    </ModalStack>
  );
};

const IntializingStep = () => {
  return (
    <ModalStack>
      <UpdateHeader type={'remote'} />

      <VStack w="full" data-testid="update-content">
        <HStack w="full" justifyContent="space-between">
          <TranslateText variant="openSans24" text="update.initializing" />
        </HStack>

        <StrippedProgressBar value={100} />
      </VStack>
    </ModalStack>
  );
};

interface SummaryStepProps {
  onRestart: () => void;
  disabled: boolean;
  source: AppSource;
}

const SummaryStep = ({ onRestart, disabled, source }: SummaryStepProps) => {
  return (
    <ModalStack>
      <UpdateHeader type={source} />
      <TranslateText
        variant="openSans24"
        text="update.restartStatement"
        textAlign="center"
        data-testid="update-content"
      />
      <MainButton
        text="update.restart"
        isDisabled={disabled}
        variant="mdPrimaryButton"
        onClick={onRestart}
        minW="32"
        data-testid="update-restart-button"
      />
    </ModalStack>
  );
};

export const UpdateSoftware = ({ close }: ModalProps) => {
  const { updateStep, status, availableUpdate } = useAppSelector(state => state.update.software);
  const areModulesPresent = useCheckDeviceModulesPresence();
  const dispatch = useAppDispatch();

  const timerRef = useRef<NodeJS.Timeout>();
  const checkRef = useRef(false);

  useEffect(() => {
    if (status.updateStatus === 'error' || status.initializeUpdate === 'error') {
      close();
    }
  }, [close, status]);

  useEffect(() => {
    if (updateStep?.step === 'progress')
      timerRef.current = setInterval(() => {
        if (!checkRef.current) {
          dispatch(checkUpdateStatus('software'));
          checkRef.current = true;
        }
      }, QUERY_INTERVAL);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [dispatch, updateStep?.step]);

  useEffect(() => {
    checkRef.current = false;
    if (updateStep?.step === 'progress') {
      if (updateStep.completed) {
        dispatch(
          setUpdateSoftwareStep({
            step: 'summary',
          }),
        );
      }
    }
  }, [dispatch, updateStep]);

  const getStep = () => {
    if (!updateStep) {
      return <></>;
    }

    if (updateStep.step === 'initializing') {
      return <IntializingStep />;
    }

    if (!availableUpdate) {
      return <></>;
    }
    switch (updateStep.step) {
      case 'extensions-check':
        return (
          <ExtensionsCheckStep
            onCancel={() => {
              close();
              dispatch(setUpdateSoftwareStep());
            }}
            onUpdate={() => {
              dispatch(initializeUpdate('software'));
            }}
          />
        );
      case 'question':
        return (
          <QuestionStep
            version={availableUpdate?.version}
            source={availableUpdate?.source}
            onCancel={() => {
              dispatch(setUpdateSoftwareStep());
            }}
            onUpdate={() => {
              dispatch(
                areModulesPresent
                  ? initializeUpdate('software')
                  : setUpdateSoftwareStep({
                      step: 'extensions-check',
                    }),
              );
            }}
          />
        );
      case 'progress':
        return <ProgressStep progress={updateStep.progress} source={availableUpdate?.source} />;
      case 'summary':
        return (
          <SummaryStep
            source={availableUpdate?.source}
            disabled={status.initializeRestart === 'inprogress'}
            onRestart={() => {
              dispatch(initializeRestart('software'));
            }}
          />
        );
    }
  };
  let width = { base: 'lg', '2xl': '2xl' };
  if (updateStep?.step == 'extensions-check') {
    width = { base: '42rem', '2xl': '60rem' };
  }
  return (
    <ModalContent maxW={width} borderRadius="rMd" p="3">
      <ModalBody>
        <Container variant="modalBox" px={0}>
          {getStep()}
        </Container>
      </ModalBody>
    </ModalContent>
  );
};

export const UpdateFirmware = ({ close }: ModalProps) => {
  const { expected } = useFirmwareVersion();
  const { updateStep, status } = useAppSelector(state => state.update.firmware);
  const areModulesPresent = useCheckDeviceModulesPresence();
  const dispatch = useAppDispatch();

  const timerRef = useRef<NodeJS.Timeout>();
  const checkRef = useRef(false);

  useEffect(() => {
    if (updateStep?.step === 'progress')
      timerRef.current = setInterval(() => {
        if (!checkRef.current) {
          dispatch(checkUpdateStatus('firmware'));
          checkRef.current = true;
        }
      }, QUERY_INTERVAL);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [dispatch, updateStep?.step]);

  useEffect(() => {
    checkRef.current = false;
    if (updateStep?.step === 'progress') {
      if (updateStep.completed) {
        dispatch(
          setUpdateFirmwareStep({
            step: 'summary',
          }),
        );
      }
    }
  }, [dispatch, updateStep]);

  const getStep = () => {
    if (!updateStep) {
      return null;
    }
    switch (updateStep.step) {
      case 'question':
        return (
          <QuestionStep
            version={expected?.map(v => `${v.moduleId} - ${convertVersionToString(v.version)}`).join('\n') ?? '???'}
            source="firmware"
            onCancel={() => {
              close();
            }}
            onUpdate={() =>
              dispatch(
                areModulesPresent
                  ? initializeUpdate('firmware')
                  : setUpdateFirmwareStep({
                      step: 'extensions-check',
                    }),
              )
            }
          />
        );
      case 'extensions-check':
        return (
          <ExtensionsCheckStep
            onCancel={() => {
              close();
              dispatch(
                setUpdateFirmwareStep({
                  step: 'question',
                }),
              );
            }}
            onUpdate={() => {
              dispatch(initializeUpdate('firmware'));
            }}
          />
        );
      case 'progress':
        return <ProgressStep progress={updateStep.progress} source="firmware" />;
      case 'summary':
        return (
          <SummaryStep
            source="firmware"
            disabled={status.initializeRestart === 'inprogress'}
            onRestart={() => {
              dispatch(initializeRestart('firmware'));
            }}
          />
        );
    }
  };

  let width = { base: 'lg', '2xl': '2xl' };
  if (updateStep?.step == 'extensions-check') {
    width = { base: '42rem', '2xl': '60rem' };
  }
  return (
    <ModalContent maxW={width} borderRadius="rMd" p="3">
      <ModalBody>
        <Container variant="modalBox" px={0}>
          {getStep()}
        </Container>
      </ModalBody>
    </ModalContent>
  );
};

export const UpdateModal = (props: ModalProps & { id: 'software' | 'firmware' }) => {
  switch (props.id) {
    case 'firmware':
      return <UpdateFirmware {...props} />;
    case 'software':
      return <UpdateSoftware {...props} />;
    default:
      throw new Error(`Given id ${props.id} is not supported`);
  }
};
