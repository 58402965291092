import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Center, Container } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateInput } from 'containers/common/CreateInput';
import { successNotification } from 'helpers/handleNotifications';
import { useSpecialistList } from 'hooks/lists/useSpecialistList';
import { useAppSelector } from 'hooks/store';
import { LocalStorageManager } from 'libs/exo-session-manager/core/global/LocalStorageManager';
import { emailSchema } from 'schemas/email';
import { exoClinicApi } from 'services/ExoClinicBackendApi';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';
import { TranslateText } from 'components/texts/TranslateText';

import { ModalProps } from './Modal';

interface ModalProp {
  email: string;
  language?: string;
}

export const InviteSpecialistModal: FC<ModalProps> = ({ close }) => {
  const { loadSpecialistList } = useSpecialistList();
  const user = useAppSelector(state => state.user);
  const facilityId = user.userData?.facilityId;
  const language = LocalStorageManager.payload.language.value;

  const methods = useForm<ModalProp>({
    mode: 'onBlur',
    resolver: yupResolver(emailSchema),
  });

  const { handleSubmit } = methods;

  const afterSuccess = () => {
    if (!facilityId) {
      throw new Error('User does not have facility ID');
    }

    successNotification('specialist.sendInvite');
    loadSpecialistList(facilityId);
    close();
  };

  const submit = async (data: ModalProp) => {
    const preparedData = { ...data, language };

    if (!facilityId) {
      throw new Error('Cannot invite to facility, facilityId is missing.');
    }

    exoClinicApi.facilitySpecialists.invite(facilityId, preparedData).then(afterSuccess);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Container px="0" py="4" maxW="96">
          <MainHeading text="application.inviteSpecialist" pb="2.5" />
          <TranslateText
            variant="openSans20"
            text="application.emailSpecDescription"
            textAlign="center"
            marginBottom="4"
            color="headerTextColor"
          />
          <CreateInput type="text" uniqueErrorTxt="validation.invalidEmail" required />
          <Center mt="4">
            <MainButton text="application.sendInvite" />
          </Center>
        </Container>
      </form>
    </FormProvider>
  );
};
