import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeDateToUTC, getDateFromBackEnd, getFirstDayInMonth } from 'helpers/formatDate';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { UserProfileResponseDTO } from 'types';

export type TrainingItem = ExoClinicBackendOpenApiSchemas['TrainingResponseDto'] & {
  isDone: boolean;
  preparedPlannedDate: Date;
  preparedCompleteTime: Date;
};

interface CalendarState {
  trainingList: TrainingItem[];
  currentDisplayMonth: Date;
  patientData?: UserProfileResponseDTO;
}

const initialState: CalendarState = {
  trainingList: [],
  currentDisplayMonth: getFirstDayInMonth(),
};

export enum CalendarTrainingResult {
  DONE = 'DONE',
  UNDONE = 'UNDONE',
  CATCHED_UP = 'CATCHED_UP',
  PLANNED = 'PLANNED',
}

export interface GetTrainingListParams {
  from: string;
  to: string;
}

export interface GetTrainingListArgs {
  params: GetTrainingListParams;
  userProfileId: string;
}

export interface UpdateTrainingBody {
  dateTime: Date;
  comment?: string;
  trainingTemplateId?: string;
}

interface UpdateTrainingArgs {
  body: UpdateTrainingBody;
  trainingId: string;
}

export interface CreateTrainingBody {
  dateTime: Date;
  trainingTemplateId: string;
  userProfileId: string;
  comment?: string;
}

export interface MoveTrainingsByDaysArgs {
  params: MoveTrainingsByDaysParams;
  userProfileId: string;
}

export interface MoveTrainingsByDaysParams {
  numberOfDays: number;
  givenDay: string;
}

export interface MoveSingleTrainingArgs {
  trainingId: string;
  targetDay: string;
}

export interface MoveAllDayArgs {
  body: MoveAllDayBody;
  userProfileId: string;
  errorCallback: () => void;
}

export interface MoveAllDayBody {
  givenDay: string;
  targetDay: string;
}

interface ChangeTrainingDateArgs {
  preparedPlannedDate: Date;
  trainingId: string;
}

const getPreparedTraining = (training: ExoClinicBackendOpenApiSchemas['TrainingResponseDto']): TrainingItem => {
  const preparedPlannedDate = getDateFromBackEnd(training['plannedDate'] ?? '');
  const isDone = Boolean(training.completeTime);
  const preparedCompleteTime = getDateFromBackEnd(training.completeTime ?? '');

  return { ...training, isDone, preparedPlannedDate, preparedCompleteTime };
};

const getPreparedTrainings = (trainings: ExoClinicBackendOpenApiSchemas['TrainingResponseDto'][]): TrainingItem[] =>
  trainings.map(training => getPreparedTraining(training));

const getTrainingFromList = (trainingList: TrainingItem[], trainingId: string) =>
  trainingList.find(item => item.id === trainingId);

export const getTrainingList = createAsyncThunk('trainings', async (args: GetTrainingListArgs) => {
  const { params, userProfileId } = args;

  const trainings = await exoClinicApi.trainings.userProfile(userProfileId).all(params);

  return trainings;
});

// todo remove if unused
export const updateTraining = createAsyncThunk('trainings/update', async (args: UpdateTrainingArgs) => {
  const { body, trainingId } = args;
  const preparedBody = { trainingData: {}, comment: '', ...body, dateTime: changeDateToUTC(body.dateTime) };

  const training = await exoClinicApi.trainings.update({ trainingId }, preparedBody);

  return training;
});

export const createTraining = createAsyncThunk('trainings/create', async (body: CreateTrainingBody) => {
  const training = await exoClinicApi.trainings.create({
    comment: '',
    ...body,
    dateTime: changeDateToUTC(body.dateTime).toISOString(),
  });

  return training;
});

export const moveTrainingsByDays = createAsyncThunk('trainings/moveAll', async (args: MoveTrainingsByDaysArgs) => {
  const { params, userProfileId } = args;

  const trainings = await exoClinicApi.trainings.userProfile(userProfileId).moveFutureTrainings(params);

  return trainings;
});

export const moveSingleTraining = createAsyncThunk('trainings/moveSingle', async (args: MoveSingleTrainingArgs) => {
  const { targetDay, trainingId } = args;
  const params = { targetDay };

  const trainings = await exoClinicApi.trainings.moveTraining(trainingId, params);

  return trainings;
});

export const moveAllTrainingsDay = createAsyncThunk('trainings/moveDay', async (args: MoveAllDayArgs) => {
  const { body, userProfileId, errorCallback } = args;

  const trainings = await exoClinicApi.trainings
    .userProfile(userProfileId)
    .moveTrainingDay(body)
    .catch(err => {
      errorCallback();
      throw err;
    });

  return trainings;
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCurrentDisplayMonth: (state, { payload }: PayloadAction<Date>) => {
      state.currentDisplayMonth = changeDateToUTC(payload);
    },
    changeTrainingDate: (state, { payload }: PayloadAction<ChangeTrainingDateArgs>) => {
      const { preparedPlannedDate, trainingId } = payload;
      const training = getTrainingFromList(state.trainingList, trainingId);
      preparedPlannedDate.setDate(preparedPlannedDate.getDate() + 1);

      if (training) {
        training.preparedPlannedDate = preparedPlannedDate;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getTrainingList.fulfilled, (state, { payload }) => {
      state.trainingList = getPreparedTrainings(payload.trainings);

      if (!state.patientData || state.patientData.id !== payload.userProfile.id) {
        state.patientData = payload.userProfile;
      }
    });

    builder.addCase(createTraining.fulfilled, (state, { payload }) => {
      const training = getTrainingFromList(state.trainingList, payload.id);
      if (training) {
        return;
      }

      state.trainingList.push(getPreparedTraining(payload));
    });

    builder.addCase(updateTraining.fulfilled, (state, { payload }) => {
      state.trainingList.forEach((training, index) => {
        if (training.id === payload.id) {
          state.trainingList[index] = getPreparedTraining(payload);
          return;
        }
      });
    });

    builder.addCase(moveSingleTraining.fulfilled, (state, { payload }) => {
      const index = state.trainingList.findIndex(training => training.id === payload.id);
      if (index !== -1) {
        state.trainingList[index] = getPreparedTraining(payload);
      }
    });

    builder.addCase(moveTrainingsByDays.fulfilled, (state, { payload }) => {
      if (!state.trainingList.length) {
        state.trainingList = getPreparedTrainings(payload);
        return;
      }

      payload.forEach(movedTraining => {
        const indexIfExist = state.trainingList.findIndex(training => training.id === movedTraining.id);

        const preparedTraining = getPreparedTraining(movedTraining);

        if (indexIfExist === -1) {
          state.trainingList.push(preparedTraining);
          return;
        }

        state.trainingList[indexIfExist] = preparedTraining;
      });
    });

    builder.addCase(moveAllTrainingsDay.fulfilled, (state, { payload }) => {
      if (!state.trainingList.length) {
        state.trainingList = getPreparedTrainings(payload);
        return;
      }

      payload.forEach(movedTraining => {
        const indexIfExist = state.trainingList.findIndex(training => training.id === movedTraining.id);

        if (indexIfExist === -1) {
          return;
        }

        state.trainingList[indexIfExist] = getPreparedTraining(movedTraining);
      });
    });
  },
});

export const { setCurrentDisplayMonth, changeTrainingDate } = calendarSlice.actions;

export default calendarSlice.reducer;
