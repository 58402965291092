import { GeneratedExerciseDefinition, isSpecificGeneratedExerciseDefinition } from 'libs/exo-session-manager/core';

export const getRequiredChannelFeaturesFromDefinition = (program: GeneratedExerciseDefinition) => {
  if (!program) return;
  if (isSpecificGeneratedExerciseDefinition(program, ['emg', 'emg-pelvic', 'emg-game'])) {
    return program.emg.program.requiredCableChannelFeatures;
  }
  if (isSpecificGeneratedExerciseDefinition(program, ['ems', 'ems-emg'])) {
    return program.ems.program.requiredCableChannelFeatures;
  }
};
