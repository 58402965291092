import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Center, Flex, ModalBody, ModalContent, VStack } from '@chakra-ui/react';
import { PasswordInputWithMask } from 'containers/common/PasswordInputWithMask';
import { errorNotification } from 'helpers/handleNotifications';
import { logger } from 'helpers/logger';
import { useAppDispatch } from 'hooks/store';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { exoClinicApiWithoutError } from 'services/ExoClinicBackendApi';
import { authServiceMode } from 'slices/userSlice';

import { MainButton } from 'components/buttons/MainButton';
import { MainHeading } from 'components/texts/MainHeading';

import { ModalProps } from './Modal';

export function ServiceHelpModal({ close }: ModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const methods = useForm<{ password: string }>({
    mode: 'onSubmit',
  });
  const submit = useCallback(
    async (data: { password: string }) => {
      try {
        const response = await exoClinicApiWithoutError.service.auth({ password: data.password });
        if (response.status === 204) {
          dispatch(authServiceMode(true));
          close();
          navigate('/service-mode');
        }
      } catch (error) {
        logger.debug('ServiceHelpModal', 'User not authorized', error);
        dispatch(authServiceMode(false));
        errorNotification('serviceMode.notAuthorized');
      }
    },
    [close, dispatch, navigate],
  );
  return (
    <ModalContent maxW="lg" borderRadius="rSm" p="4">
      <ModalBody>
        <VStack w="full" justify="space-between" gap="6" pt="4" flex="1 0">
          <Center>
            <MainHeading variant="subheading36" text={'serviceMode.modalHeader'} />
          </Center>
          <FormProvider {...methods}>
            <form style={{ width: '100%' }} noValidate onSubmit={methods.handleSubmit(submit)}>
              <VStack w="full" justify="space-between" gap="10">
                <PasswordInputWithMask pr="12" />
                <Flex w="full" justifyContent="space-between" px="4">
                  <MainButton
                    type="button"
                    text="common.cancel"
                    variant={{ base: 'smOutlinedPrimaryButton', '2xl': 'mdOutlinedPrimaryButton' }}
                    onClick={() => close()}
                    minW={32}
                  />
                  <MainButton
                    type="submit"
                    text="common.next"
                    variant={{ base: 'smPrimaryButton', '2xl': 'mdPrimaryButton' }}
                    width={32}
                  />
                </Flex>
              </VStack>
            </form>
          </FormProvider>
        </VStack>
      </ModalBody>
    </ModalContent>
  );
}
