import { InterpolationOptions } from 'node-polyglot';

import { notification, NotificationOptions } from 'components/common/Notification';

export const successNotification = (title: string, replace?: InterpolationOptions, options?: NotificationOptions) => {
  return notification(title, 'success', replace, options);
};

export const errorNotification = (title: string, replace?: InterpolationOptions, options?: NotificationOptions) => {
  return notification(title, 'error', replace, options);
};

export const fatalNotification = (title: string, replace?: InterpolationOptions, options?: NotificationOptions) => {
  return notification(title, 'error', replace, { duration: null, ...options });
};

export const warningNotification = (title: string, replace?: InterpolationOptions, options?: NotificationOptions) => {
  return notification(title, 'warning', replace, options);
};
