import { CalibrationFlowStateIdentifier } from '../common/CalibrationFlow';

import { CalibrationFlowDefinitionStates } from './types';

export function prepareChannelsAndConnectElectrodesFlow(
  prev: CalibrationFlowStateIdentifier | null,
  next: CalibrationFlowStateIdentifier | null,
  stepIndex: number,
  options?: {
    skipMuscleSelector?: boolean;
    skipChannelRoleSelector?: boolean;
  },
): CalibrationFlowDefinitionStates {
  const result: CalibrationFlowDefinitionStates = {};
  if (!options?.skipMuscleSelector) {
    result['channel-muscle-selector'] = {
      next: null,
      prev: null,
      stepIndex,
      disableAlerts: ['disconnectedElectrode'],
    };
  }
  if (!options?.skipChannelRoleSelector) {
    result['channel-role-selector'] = {
      prev: null,
      next: null,
      disableAlerts: ['disconnectedElectrode'],
      condition: 'skipRoleScreen',
    };
  }
  result['connect-electrodes'] = {
    prev: null,
    next: null,
    disableAlerts: ['disconnectedElectrode'],
  };
  const keys = Object.keys(result) as CalibrationFlowStateIdentifier[];
  Object.entries(result).forEach(([k, v], i) => {
    v.stepIndex = stepIndex + i;
    v.prev = prev;
    v.next = keys[i + 1] ?? next;
    prev = k as CalibrationFlowStateIdentifier;
  });

  return result;
}
