import { theme } from '@chakra-ui/react';

import colors from '../colors';
import sizes from '../sizes';

import { parseStyleConfig } from './helpers';

const variants = [
  'unauthorizedBox',
  'loginBox',
  'scrollBox',
  'glassAbsoluteBox',
  'glassBox',
  'mainBox',
  'fullPageCenter',
  'fullPageGradient',
  'listHeader',
  'headerBox',
  'selectedDeviceBox',
  'userBox',
  'profileCard',
  'profileText',
  'addForm',
  'boxForm',
  'listBox',
  'menu',
  'card',
  'mainClinic',
  'clinicStatisticsContainer',
  'iconCard',
  'editAccount',
  'targetBox',
  'targetTextBox',
  'avatarBox',
  'avatar',
  'spaceBox',
  'patientAvatar',
  'noteBox',
  'note',
  'noteText',
  'buttonBox',
  'connectingBox',
  'deviceBox',
  'connectedDeviceBox',
  'modalBox',
  'systemComponentsBox',
  'sideImage',
  'menuHeader',
  'filterBox',
  'trainingBox',
  'exerciseCalibration',
  'electrodesConnection',
  'emsCalibrationItem',
  'electrodesInfo',
  'chatList',
  'chatListItem',
  'chatListTime',
  'chatListUnread',
  'chatItemData',
  'chatItemAvatar',
  'chatListMessage',
  'chatBox',
  'chatBoxInput',
  'messageBox',
  'chatMessageHeader',
  'chatMessageRight',
  'chatMessageLeft',
  'trainingDone',
  'trainingNoDone',
  'toolbarMonth',
  'toolbarButton',
  'toolbarAvatar',
  'weekDayHeader',
  'noCurrentDay',
  'currentDay',
  'eventDay',
  'allDayEvent',
  'trainingDayDone',
  'cleanDiv',
  'emgCalibration',
  'emsCalibration',
  'connectElectrodes',
  'calibrationFlowMainWrapper',
  'calibrationFlowIlustrationWrapper',
  'calibrationFlowDescriptionWrapper',
  'exerciseContainer',
  'errorBox',
  'passForgetText',
  'electrostimWarning',
  'stickyBox',
  'patientListHeader',
  'alert',
  'statisticsSubheader',
  'statisticsReportContent',
  'statisticsRectangle',
  'clinicStatisticsRectangle',
  'menuBackground',
  'passwordBoxValid',
  'relativeBox',
  'patientAddBox',
  'checkedSpecialistBox',
  'checkedSpecialistItem',
  'clinicBoxForm',
  'systemSettings',
] as const;

export type Variants = (typeof variants)[number];

const Container = parseStyleConfig(variants, {
  variants: {
    unauthorizedBox: () => ({
      position: 'relative',
      margin: ['70px auto', '70px auto', null, 'auto'],
      maxWidth: ['90%', '70%', '50%', 'md'],
      backgroundColor: 'white',
      padding: '5',
      borderRadius: 'rMd',
    }),
    loginBox: () => ({
      position: 'relative',
      margin: ['70px auto', 'auto'],
      maxW: ['90%', 'md'],
      backgroundColor: 'white',
      p: '10',
      pb: '6',
      borderRadius: 'rMd',
      shadow: 'sm',
    }),
    scrollBox: () => ({
      minWidth: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    }),
    glassAbsoluteBox: () => ({
      position: 'absolute',
      borderRadius: 'rSm',
      padding: '0',
      top: ['1', '12'],
      right: ['1', '10'],
      fontSize: ['lg', null, null, 'xl'],
      background: ['stellaBioPrimaryColor', 'whiteAlpha.200'],
      width: '60',
      zIndex: '1',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
    }),
    glassBox: () => ({
      borderRadius: 'rSm',
      padding: '0',
      fontSize: ['lg', null, null, 'xl'],
      background: ['stellaBioPrimaryColor', 'whiteAlpha.200'],
      zIndex: '1',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
    }),
    mainBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      minWidth: '100%',
      padding: '0',
      minHeight: '100vh',
      background: 'backgroundColor',
      height: '100%',
      overflowY: 'hidden',
      position: 'relative',
    }),
    fullPageCenter: () => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      m: '0',
      minHeight: '100vh',
      minWidth: '100vw',
      bgGradient: colors.blueGradient,
    }),
    fullPageGradient: () => ({
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      m: '0',
      p: '0',
      minHeight: '100vh',
      minWidth: '100vw',
      bgGradient: colors.blueGradient,
    }),
    listHeader: () => ({
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
      mt: '0',
      zIndex: 0,
      minWidth: '100%',
      justifyContent: 'space-between',
    }),
    headerBox: () => ({
      display: 'flex',
      px: '0',
      py: '1',
      minW: '100%',
      maxH: sizes['navbarHeight'],
      minH: sizes['navbarHeight'],
      justifyContent: 'space-between',
    }),
    selectedDeviceBox: () => ({
      role: 'group',
      display: 'flex',
      color: 'white',
      borderRadius: 'rMd',
      background: 'whiteAlpha.200',
      justifyContent: 'space-between',
      transition: '250ms ease-in-out',
      minW: '72',
      h: '20',
      px: '4',
      _hover: {
        background: 'whiteAlpha.400',
      },
    }),
    userBox: () => ({
      display: 'flex',
      background: 'white',
      shadow: 'sm',
      minHeight: '24',
      minWidth: '100%',
      marginTop: '0.5',
      borderRadius: 'rSm',
      pl: '1',
      pr: '0px',
      _first: {
        mt: ['1', null, null, '0'],
      },
    }),
    profileCard: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderRadius: 'rMd',
      p: ['0', null, null, '2'],
      maxH: '100%',
      transition: '250ms ease-in-out',
      color: 'white',
      cursor: 'pointer',
    }),
    profileText: () => ({
      display: ['none', null, null, 'flex'],
      textAlign: 'right',
      mr: '1',
      flexDirection: 'column',
      fontSize: 'xl',
      maxW: 300,
    }),
    addForm: () => ({
      maxWidth: ['90%', '70%', '50%', 'container.sm'],
      my: '4',
      mx: 'auto',
      p: '10',
      background: 'white',
      borderRadius: 'rMd',
    }),
    boxForm: () => ({
      minW: '100%',
      shadow: 'sm',
      m: '0px',
      p: '10',
      background: 'white',
      borderRadius: 'rMd',
    }),
    listBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      m: '0 auto',
      h: `calc(100vh - ${theme.sizes['36']})`,
      minWidth: ['90%', null, null, '85%', '5xl'],
    }),
    menu: () => ({
      background: 'white',
      color: 'headerTextColor',
      py: '4',
      px: '0',
      transition: '250ms ease-in-out',
      _hover: { background: 'gray.300', cursor: 'pointer' },
    }),
    card: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      background: 'white',
      pl: '9',
      pr: '4',
      py: '5',
      alignItems: 'center',
      fontSize: ['lg', 'xl', '2xl', '2xl'],
      color: 'headerTextColor',
      borderRadius: 'rMd',
    }),
    mainClinic: () => ({
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      minWidth: ['90%', null, null, '5xl'],
    }),
    clinicStatisticsContainer: () => ({
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      minWidth: ['90%', null, null, '5xl'],
    }),
    iconCard: () => ({
      display: 'flex',
      justifyContent: 'center',
      p: '0',
      w: '20%',
      m: '0',
      textAlign: 'right',
      fontSize: '64px',
    }),
    editAccount: () => ({
      minW: ['90%', '70%', '50%', 'container.sm'],
      shadow: 'sm',
      p: '10',
      background: 'white',
      borderRadius: 'rMd',
      my: '4',
      mx: 'auto',
      display: 'flex',
      flexDirection: 'column',
    }),
    targetBox: () => ({
      p: '0',
      textAlign: 'left',
      minW: '100%',
    }),
    targetTextBox: () => ({
      p: '2',
      m: '0',
      fontSize: ['md', null, null, 'xl'],
      minW: '100%',
      maxH: '40',
      overflowY: 'auto',
      borderRadius: 'rSm',
      border: '1px solid',
      borderColor: 'gray.300',
    }),
    avatarBox: () => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      fontSize: ['md', null, null, 'lg'],
      textAlign: 'center',
      p: '0',
      my: '2.5',
      mx: 'auto',
      minW: '100%',
    }),
    avatar: () => ({
      p: 0,
      display: 'flex',
      justifyContent: 'center',
      mt: '1',
      mb: '4',
      h: '36',
      w: '36',
    }),
    spaceBox: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      minW: '100%',
      p: '0',
    }),
    patientAvatar: () => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minW: '100%',
      p: '0',
      pb: '4',
      borderBottom: '1px solid',
      borderColor: 'backgroundColor',
    }),
    noteBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      minW: '100%',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'backgroundColor',
      pb: '5',
      pt: '5',
      px: '0',
    }),
    note: () => ({
      display: 'flex',
      alignItems: 'center',
      fontSize: 'xl',
      mx: '0',
      minH: '12',
      minW: '100%',
      bg: 'noteBackgroundColor',
      borderRadius: 'rSm',
      color: 'egzotechPrimaryColor',
      position: 'relative',
    }),
    noteText: () => ({
      display: 'flex',
      alignItems: 'center',
    }),
    buttonBox: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      minW: '100%',
      p: '0',
      mt: '5',
    }),
    connectingBox: () => ({
      m: 'auto',
      padding: 0,
      fontSize: ['md', 'lg', null, 'xl'],
      maxW: ['452px', null, null, '908px'],
    }),
    deviceBox: () => ({
      display: 'flex',
      color: 'white',
      background: 'whiteAlpha.200',
      minH: '16',
      borderRadius: 'rMd',
      transition: '250ms ease-in-out',
      justifyContent: 'space-between',
      w: '440px',
      h: '124px',
      mx: 'auto',
      my: '0',
      p: '25px',
    }),
    connectedDeviceBox: () => ({
      display: 'flex',
      color: 'headerTextColor',
      background: 'whiteAlpha.700',
      minH: '16',
      borderRadius: 'rMd',
      transition: '250ms ease-in-out',
      justifyContent: 'space-between',
      w: '440px',
      h: '124px',
      mx: 'auto',
      my: '0',
      p: '25px',
    }),
    modalBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      px: '2',
    }),
    systemComponentsBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      opacity: '0.7',
      background: 'gray.300',
      px: '2',
    }),
    sideImage: () => ({
      display: 'flex',
      flexDirection: ['column', null, null, 'row'],
      maxW: '80%',
      margin: 'auto',
      alignItems: 'center',
    }),
    menuHeader: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      py: '2',
      px: '0',
      minW: '100%',
    }),
    filterBox: () => ({
      display: 'flex',
      minW: '100%',
      flexDirection: 'column',
      mb: '0',
      bg: 'white',
      p: '4',
      borderRadius: 'rSm',
      shadow: 'sm',
    }),
    trainingBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      maxW: '100%',
      h: ['100%', null, null, `calc(100vh - ${sizes.navbarHeight})`],
      m: '0 auto',
      p: '2',
    }),
    exerciseCalibration: () => ({
      minW: '100%',
      mx: 'auto',
      d: 'flex',
      flexGrow: '1',
    }),
    electrodesConnection: () => ({
      boxShadow: 'md',
      borderRadius: 'rMd',
      display: 'flex',
      mr: '50px',
      aspectRatio: '1',
      my: 'auto',
      maxW: '80ch',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }),
    emsCalibrationItem: () => ({
      cursor: 'pointer',
      w: '100%',
      boxShadow: 'md',
      borderRadius: 'rMd',
      backgroundSize: 'cover',
      aspectRatio: '1',
      p: '1',
      d: 'flex',
      flexDir: 'column',
    }),
    electrodesInfo: () => ({
      display: 'flex',
      flexDirection: 'column',
      ml: '50px',
      mr: '24',
      aspectRatio: '1',
      my: 'auto',
      maxW: '80ch',
      textAlign: 'center',
    }),
    chatList: () => ({
      display: 'flex',
      flexDirection: ['row', null, null, 'column'],
      m: '0',
      p: ['1', null, null, '0'],
      borderRadius: 'rMd',
      shadow: 'sm',
      bg: 'white',
      minW: ['100%', null, null, '25%'],
      minH: '16',
      textAlign: 'left',
      color: 'headerTextColor',
      fontSize: 'lg',
      overflow: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    }),
    chatListItem: () => ({
      display: 'flex',
      px: '8',
      py: '6',
      cursor: 'pointer',
      borderRadius: ['rMd', null, null, '0'],
      minH: [null, null, null, '100px'],
      alignItems: 'center',
    }),
    chatListTime: () => ({
      display: ['none', null, null, 'flex'],
      flexDirection: 'column',
      alignItems: 'space-between',
      textAlign: 'center',
      fontSize: 'md',
      flex: '0 1',
      p: '0',
    }),
    chatListUnread: () => ({
      display: ['none', null, null, 'block'],
      p: '0',
      textAlign: 'center',
      fontSize: 'md',
      color: 'white',
      bg: 'egzotechPrimaryColor',
      borderRadius: 'oval',
      w: '6',
    }),
    chatItemData: () => ({
      display: ['none', null, null, 'block'],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: '1 0',
      fontWeight: 'bold',
    }),
    chatItemAvatar: () => ({
      display: 'flex',
      p: '0',
      flex: '0 1',
    }),
    chatListMessage: () => ({
      display: 'flex',
      alignItems: 'center',
      p: '0',
    }),
    chatBox: () => ({
      display: 'flex',
      flexDirection: 'column',
      m: '0',
      p: '0',
      minH: [`calc(100% - ${sizes.navbarHeight})`, null, null, '100%'],
      borderRadius: 'rMd',
      shadow: 'sm',
      bg: 'white',
      minW: ['100%', null, null, '65%'],
      color: 'headerTextColor',
      fontSize: 'lg',
    }),
    chatBoxInput: () => ({
      display: 'flex',
      minW: '100%',
      my: '4',
      mx: 'auto',
    }),
    messageBox: () => ({
      p: 0,
      m: '0',
      display: 'flex',
      flex: '1,0',
      minW: '100%',
      overflowX: 'hidden',
    }),
    chatMessageHeader: () => ({
      display: 'flex',
      borderBottom: '1px solid lightGrey',
      p: ['2', null, null, '6'],
      minW: '100%',
      alignItems: 'center',
      maxH: ['12', null, null, '100%'],
      fontSize: ['lg', null, null, '2xl'],
      fontWeight: 'bold',
    }),
    chatMessageRight: () => ({
      position: 'relative',
      background: 'noteBackgroundColor',
      color: 'headerTextColor',
      fontSize: ['md', null, null, 'lg'],
      p: '2',
      m: '2',
      ml: ['0', null, null, '50%'],
      maxW: ['100%', null, null, '50%'],
      borderRadius: 'rMd',
      _after: {
        content: `''`,
        position: 'absolute',
        display: 'block',
        width: '0',
        borderStyle: 'solid',
        borderWidth: `${theme.sizes['6']} ${theme.sizes['6']} 0 0`,
        borderColor: `${colors.noteBackgroundColor} transparent transparent transparent`,
        top: '0',
        right: '-4',
      },
    }),
    chatMessageLeft: () => ({
      position: 'relative',
      background: 'inputDisabledBackgroundColor',
      color: 'headerTextColor',
      p: '2',
      fontSize: ['md', null, null, 'lg'],
      my: '2',
      mx: '0',
      mr: ['0', null, null, '50%'],
      maxW: ['100%', null, null, '50%'],
      borderRadius: 'rMd',
      _after: {
        content: `''`,
        position: 'absolute',
        display: 'block',
        width: '0',
        borderStyle: 'solid',
        borderWidth: `0 ${theme.sizes['6']} ${theme.sizes['6']} 0`,
        borderColor: `transparent ${colors.inputDisabledBackgroundColor} transparent transparent`,
        top: '0',
        left: '-4',
      },
    }),
    trainingDone: () => ({
      color: 'successColor',
      display: 'flex',
      direction: 'row',
      justifyContent: 'center',
    }),
    trainingNoDone: () => ({
      color: 'errorColor',
      display: 'flex',
      direction: 'row',
      justifyContent: 'center',
    }),
    toolbarMonth: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontWeight: 'bold',
      p: '0px',
      gap: '4',
      fontSize: '2xl',
      textTransform: 'capitalize',
    }),
    toolbarButton: () => ({
      display: 'flex',
      flexDirection: ['column', null, null, 'row'],
      p: '0px',
      gap: '4',
      justifyContent: 'flex-end',
    }),
    toolbarAvatar: () => ({
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '2xl',
      p: '0px',
      alignItems: 'center',
    }),
    weekDayHeader: () => ({
      fontSize: 'lg',
      fontWeight: 'normal',
      textTransform: 'capitalize',
      borderLeft: 'none',
      mb: '2',
    }),
    noCurrentDay: () => ({
      display: 'flex',
      justifyContent: 'center',
      mb: '1',
      maxW: '40px',
      fontSize: 'xl',
      lineHeight: 'md',
      fontFamily: 'opensans-light',
      p: '0',
      '&.drag': {
        _hover: {
          cursor: 'move',
          alignItems: 'center',
          bg: 'stellaBioSecondaryColor',
          borderRadius: 'rSm',
          color: 'stellaBioPrimaryColor',
          fontWeight: 'bold',
        },
      },
    }),
    currentDay: () => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      mb: '1',
      p: '0',
      maxW: '40px',
      fontSize: 'xl',
      lineHeight: 'md',
      fontFamily: 'opensans-light',
      '&.drag': {
        _hover: {
          cursor: 'move',
          alignItems: 'center',
          bg: 'stellaBioSecondaryColor',
          borderRadius: 'rSm',
          color: 'stellaBioPrimaryColor',
          fontWeight: 'bold',
        },
      },
    }),
    eventDay: () => ({
      display: 'flex',
      minH: '10',
      alignItems: 'center',
      bg: 'stellaBioSecondaryColor',
      borderRadius: 'rSm',
      fontWeight: 'bold',
      cursor: 'move',
    }),
    allDayEvent: () => ({
      minH: '96px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'rSm',
      color: 'stellaBioPrimaryColor',
      bg: 'stellaBioSecondaryColor',
      fontWeight: 'bold',
      fontSize: '3xl',
    }),
    trainingDayDone: () => ({
      w: '3',
      h: '3',
      p: '0',
      borderRadius: 'oval',
      position: 'absolute',
      top: '20%',
      left: '58%',
    }),
    cleanDiv: () => ({
      m: '0',
      p: '0',
      w: '100%',
      maxW: '100%',
    }),
    emgCalibration: () => ({
      w: '100%',
      h: '100%',
      minW: '100%',
      display: 'flex',
      flexGrow: '1',
      flexDirection: 'column',
      mx: 0,
      py: 6,
      px: 14,
    }),
    emsCalibration: () => ({
      w: '80%',
      maxW: 'unset',
      flexDirection: 'column',
      mx: 'auto',
      px: 10,
      py: 6,
    }),
    connectElectrodes: () => ({
      my: 'auto',
      py: '16',
      h: '100%',
      px: '16',
      display: 'flex',
      minW: '100%',
    }),
    calibrationFlowMainWrapper: () => ({
      my: 'auto',
      h: '100%',
      px: '0',
      display: 'flex',
      maxW: '120rem',
    }),
    calibrationFlowIlustrationWrapper: () => ({
      boxShadow: 'md',
      borderRadius: 'rLg',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      py: '12',
      ml: '24',
      aspectRatio: '1',
      my: 'auto',
      maxW: '3xl',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }),
    calibrationFlowDescriptionWrapper: () => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      aspectRatio: '1',
      mr: '24',
      my: 'auto',
      maxW: '3xl',
      textAlign: 'center',
      paddingX: [7, 7, 7, 7, 10],
    }),

    exerciseContainer: () => ({
      my: 'auto',
      py: '16',
      h: '100%',
      px: '16',
      display: 'flex',
      minW: '100%',
      flexDir: 'column',
    }),
    errorBox: () => ({
      bg: 'errorLightColor',
      color: 'errorMediumColor',
      borderWidth: theme.sizes['0.5'],
      borderColor: 'errorMediumColor',
      fontSize: 'lg',
      p: '2',
      mb: '2',
      borderRadius: 'rMd',
      textAlign: 'center',
    }),
    passForgetText: () => ({
      mt: '1',
      mr: '0',
      mb: '0.5',
      ml: 'auto',
      p: '0',
      maxW: ['100%', null, null, '50%'],
    }),
    electrostimWarning: () => ({
      display: 'flex',
      flexDir: 'column',
      mW: ['90%', '60%', '40%'],
      m: 'auto',
      textAlign: 'center',
      alignItems: 'center',
    }),
    stickyBox: () => ({
      position: 'sticky',
      top: '0',
      minW: '100%',
      m: '0',
      p: '0',
      zIndex: '99',
    }),
    patientListHeader: () => ({
      display: 'flex',
      padding: '0',
      my: '2',
      mx: '0',
      mb: '-1',
      zIndex: 0,
      minWidth: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
    }),
    alert: () => ({
      display: 'flex',
      alignItems: 'center',
      background: 'white',
      fontSize: 'xl',
      p: '4',
      borderRadius: 'rMd',
      shadow: 'alertShadow',
    }),
    statisticsSubheader: () => ({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      minW: '96%',
      mx: '4',
      py: '4',
      px: '0',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      borderColor: 'gray.300',
    }),
    statisticsReportContent: () => ({
      display: 'flex',
      flexDirection: 'column',
      minW: '96%',
      m: '4',
      p: '0',
    }),
    statisticsRectangle: () => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minW: '100%',
      maxW: 'none',
      h: '12',
      m: '0',
    }),
    clinicStatisticsRectangle: () => ({
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minW: '100%',
      maxW: 'none',
      h: '12',
      m: '0',
    }),
    menuBackground: () => ({
      position: 'fixed',
      bg: 'transparent',
      m: '0',
      p: '0',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      maxW: '100vw',
      zIndex: '4',
    }),
    passwordBoxValid: () => ({
      position: 'absolute',
      p: '2.5',
      m: '0',
      shadow: 'alertShadow',
      borderRadius: 'rSm',
      bg: 'white',
      zIndex: '10',
      w: 'auto',
      fontSize: 'lg',
    }),
    relativeBox: () => ({
      m: '0',
      p: '0',
      position: 'relative',
    }),
    patientAddBox: () => ({
      fontFamily: '"Open Sans", sans-serif',
      p: '0px',
      color: 'headerTextColor',
      fontWeight: 800,
      fontSize: ['md', null, null, 'xl'],
    }),
    checkedSpecialistBox: () => ({
      display: 'flex',
      my: '2.5',
      mx: '0',
      p: '0',
      flexWrap: 'wrap',
      gap: '2',
      maxH: '120px',
      overflowY: 'scroll',
    }),
    checkedSpecialistItem: () => ({
      color: 'gray.500',
      m: '0',
      fontSize: 'xl',
      py: '2.5',
      px: '5',
      background: 'inputDisabledBackgroundColor',
      w: 'auto',
      display: 'flex',
      gap: '3',
      borderRadius: 'rSm',
    }),
    clinicBoxForm: () => ({
      minW: '100%',
      shadow: 'sm',
      m: '0px',
      pt: '14',
      pb: '0',
      px: '10',
      background: 'white',
      borderRadius: 'rMd',
    }),
    systemSettings: () => ({
      minW: '100%',
      px: '14',
    }),
  },
});

export default Container;
