import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSearchFromString } from 'helpers/search';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';
import { FacilitySpecialistInvitationResponseDTO } from 'types';

export enum SpecialistFilterType {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  BLOCKED = 'BLOCKED',
}

export interface SpecialistListState {
  activeBlockedList: ExoClinicBackendOpenApiSchemas['FacilitySpecialistResponseDto'][];
  invitedList: FacilitySpecialistInvitationResponseDTO[];
  filter: SpecialistFilterType;
  search: string;
  nextPage: number;
  lastType: SpecialistFilterType;
  isEndOfPages: boolean;
  inProgress: boolean;
  isError: boolean;
  isLoaded: boolean;
}

const initialState: SpecialistListState = {
  activeBlockedList: [],
  invitedList: [],
  search: '',
  filter: SpecialistFilterType.ACTIVE,
  lastType: SpecialistFilterType.ACTIVE,
  nextPage: 0,
  isEndOfPages: false,
  inProgress: false,
  isError: false,
  isLoaded: false,
};

export interface GetSpecialistListArguments {
  facilityId: string;
  searchValue?: string;
  page: number;
  isBlocked?: boolean;
}

export const getActiveBlockedList = createAsyncThunk(
  'facility-specialists',
  async (arg: GetSpecialistListArguments) => {
    const { facilityId, searchValue, page, isBlocked } = arg;
    const params = { searchContent: searchValue ? getSearchFromString(searchValue) : [], blocked: isBlocked, page };
    const activeList = await exoClinicApi.facilitySpecialists.all(facilityId, params);
    return activeList;
  },
);

export const getInvitedList = createAsyncThunk(
  'facility-specialists/invitations',
  async (arg: GetSpecialistListArguments) => {
    const { facilityId, searchValue, page } = arg;
    const params = { searchContent: searchValue ? getSearchFromString(searchValue) : [], page };
    const activeList = await exoClinicApi.facilitySpecialists.invitations(facilityId, params);
    return activeList;
  },
);

const specialistListSlice = createSlice({
  name: 'specialistList',
  initialState,
  reducers: {
    setFilter: (state, { payload }: PayloadAction<SpecialistFilterType>) => {
      state.filter = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getActiveBlockedList.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getActiveBlockedList.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;

      const { pageable, content, last } = payload;

      if (!state.activeBlockedList.length || !pageable?.pageNumber || state.lastType !== state.filter) {
        state.activeBlockedList = content;
        state.nextPage = 0;
        state.lastType = state.filter;
      } else {
        content.forEach(payloadSpecialist => {
          const indexIfExist = state.activeBlockedList.findIndex(
            stateSpecialist => stateSpecialist.id === payloadSpecialist.id,
          );

          if (indexIfExist === -1) {
            state.activeBlockedList.push(payloadSpecialist);
            return;
          }

          state.activeBlockedList[indexIfExist] = payloadSpecialist;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getActiveBlockedList.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });

    builder.addCase(getInvitedList.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getInvitedList.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;

      const { pageable, content, last } = payload;

      if (!state.invitedList.length || !pageable.pageNumber) {
        state.invitedList = content;
        state.nextPage = 0;
        state.lastType = state.filter;
      } else {
        content.forEach(payloadSpecialist => {
          const indexIfExist = state.invitedList.findIndex(
            stateSpecialist => stateSpecialist.email === payloadSpecialist.email,
          );

          if (indexIfExist === -1) {
            state.invitedList.push(payloadSpecialist);
            return;
          }

          state.invitedList[indexIfExist] = payloadSpecialist;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getInvitedList.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });
  },
});

export const { setFilter, setSearch } = specialistListSlice.actions;

export default specialistListSlice.reducer;
