import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Flex, Grid, GridItem } from '@chakra-ui/react';
import { CableChannelFeature } from '@egzotech/exo-session/features/cable';
import { ChannelProgramController } from '@egzotech/exo-session/features/common';
import { extractChannelCount, getMuscleName, MuscleId, muscles, regions } from 'config/muscles';
import { Channel } from 'config/theme/colors';
import { channelsHasPelvicChannel } from 'helpers/channelsHasPelvicChannel';
import { getRequiredChannelFeaturesFromDefinition } from 'helpers/getRequiredChannelFeaturesFromDefinition';
import { __ } from 'helpers/i18n';
import { includesAnyString } from 'helpers/string';
import {
  CalibrationFlow,
  EMGExerciseDefinition,
  EMSExerciseDefinition,
  isEMSExerciseDefinition,
} from 'libs/exo-session-manager/core';
import {
  getMuscleIdsByGroups,
  getMusclesForExercise,
} from 'libs/exo-session-manager/core/definitions/stella-bio/muscle-exercise-selection';
import { useCalibrationFlowState, useDevice, useProgramSelection } from 'libs/exo-session-manager/react';
import { useBasingSettings } from 'libs/exo-session-manager/react/hooks/useBasingSettings';
import { CalibrationFlowHeader } from 'views/+patientId/training/+trainingId/_components/components/CalibrationFlowHeader';

import { MainInput } from 'components/form/MainInput';
import { MainSearchSelect } from 'components/form/MainSearchSelect';
import { SearchIcon } from 'components/icons';
import { TranslateText } from 'components/texts/TranslateText';

import { ChannelMuscleImage } from './ChannelMuscleImage';

export type ChannelToMuscleMap = Record<Channel, MuscleId>;

interface MuscleExtras {
  maxChannels?: number;
  allowedChannelFeatures?: CableChannelFeature[];
}

export const muscleExtras: { [key in MuscleId]?: MuscleExtras } = {
  'pelvic.no-side.analFemale': {
    maxChannels: 2,
    allowedChannelFeatures: ['emg-pelvic', 'ems-pelvic'],
  },
  'pelvic.no-side.analMale': {
    maxChannels: 2,
    allowedChannelFeatures: ['emg-pelvic', 'ems-pelvic'],
  },
  'pelvic.no-side.vagina': {
    maxChannels: 2,
    allowedChannelFeatures: ['emg-pelvic', 'ems-pelvic'],
  },
};

function findChannelWithMuscle(map: ChannelToMuscleMap | null, muscle: string) {
  if (!map) {
    return null;
  }

  const result = Object.entries(map)
    .sort((a, b) => +b[0] - +a[0])
    .find(([_, v]) => v === muscle)?.[0];

  return typeof result === 'string' ? +result : result ?? null;
}

function findAllChannelsWithMuscle(map: ChannelToMuscleMap | null, muscle: string) {
  if (!map) {
    return null;
  }

  const result = Object.entries(map)
    .filter(([_, v]) => v === muscle)
    .map(([k, _]) => +k as Channel);

  return result;
}

export const ChannelMuscleSelector = ({ flow }: { flow: CalibrationFlow }) => {
  const { selectedDevice, session } = useDevice();
  const { definition } = useBasingSettings(flow);
  const { selectedProgramId } = useProgramSelection();
  const [calibrationData, setCalibrationData] = useCalibrationFlowState(flow, 'channel-muscle-selector');
  const [legBasingLegSideSelectionData] = useCalibrationFlowState(flow, 'leg-basing-leg-side-selection');
  const [meissaBasingSideSelectionData] = useCalibrationFlowState(flow, 'meissa-basing-side-selection');
  const [_, setData] = useCalibrationFlowState(flow, 'channel-role-selector');
  const defaultSide = (legBasingLegSideSelectionData?.sideSelection ?? meissaBasingSideSelectionData?.sideSelection) as
    | 'left'
    | 'right'
    | undefined;
  const channelToMuscleMap = calibrationData?.channelToMuscle as ChannelToMuscleMap | null;
  const [isMuscleChangeDetected, setMuscleChangeDetected] = useState(false);
  const exerciseRequiredChannelFeatures = getRequiredChannelFeaturesFromDefinition(definition);

  const exerciseNeedsPelvicMuscle = exerciseRequiredChannelFeatures
    ? channelsHasPelvicChannel(exerciseRequiredChannelFeatures)
    : false;

  useEffect(() => {
    if (isMuscleChangeDetected) {
      setData({ channelRolesInstances: undefined });
    }
  }, [isMuscleChangeDetected, setData]);

  const { channels: cableChannels, minRequiredChannels } = useMemo(() => {
    let availableChannels: readonly number[] = [];
    let deviceMaxSupportedChannels = 0;
    let maxSupportedChannels = 0;
    let minRequiredChannels = 0;

    if (isEMSExerciseDefinition(definition)) {
      const channelController = new ChannelProgramController({
        maxSupportedChannelsByDevice: session?.options.electrostim?.maxSupportedChannels ?? 8,
        allowMappingSingleVirtualChannelToMultiplePhyscialChannels: true,
      });
      channelController.setProgram(definition.ems.program);

      if (selectedDevice?.cableType) {
        channelController.setCable(selectedDevice?.cableType);
      }
      availableChannels = channelController.availableChannels;
      minRequiredChannels = channelController.minRequiredChannels;
      deviceMaxSupportedChannels = session?.options.electrostim?.maxSupportedChannels ?? 8;
      maxSupportedChannels = channelController.maxSupportedChannels;
    } else {
      availableChannels = selectedDevice?.cableType?.channels ?? [];
      minRequiredChannels = 1;
      deviceMaxSupportedChannels = session?.options.cable?.maxSupportedChannels ?? 0;
      maxSupportedChannels = deviceMaxSupportedChannels;
    }

    return {
      channels: availableChannels.filter(v => v < deviceMaxSupportedChannels) ?? [],
      minRequiredChannels,
      maxSupportedChannels,
    };
  }, [definition, session, selectedDevice?.cableType]);

  const internalElectrodes = useMemo(
    () =>
      cableChannels
        .map(ch => ({
          channel: ch,
          channelFeatures: selectedDevice?.cable?.getChannelFeatures(ch),
        }))
        .filter(ch => ch?.channelFeatures?.includes('emg-pelvic') || ch?.channelFeatures?.includes('ems-pelvic')),
    [cableChannels, selectedDevice?.cable],
  );

  const [availableChannels, setAvailableChannels] = useState(
    channelToMuscleMap
      ? cableChannels.filter(
          v =>
            !Object.keys(channelToMuscleMap)
              .map(v => +v)
              .includes(v),
        )
      : (cableChannels as Channel[]),
  );

  const exerciseMuscleNames =
    selectedProgramId && includesAnyString(definition.type, ['emg', 'emg-pelvic', 'ems', 'ems-emg'])
      ? getMusclesForExercise(selectedProgramId, definition as EMGExerciseDefinition | EMSExerciseDefinition)
      : null;
  const deviceMuscles = exerciseMuscleNames
    ? getMuscleIdsByGroups(exerciseMuscleNames, muscles[selectedDevice?.type ?? 'stella-bio'] as readonly MuscleId[])
    : muscles[selectedDevice?.type ?? 'all'];
  const availableRegions = deviceMuscles
    .map(v => v.split('.')[0])
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter(v => regions.includes(v as (typeof regions)[number]));

  const sideSelectOptions = [
    { label: __('placeholders.none'), value: '' },
    { label: __('patientCard.left'), value: 'left' },
    { label: __('patientCard.right'), value: 'right' },
  ];

  const regionSelectOptions = [
    { label: __('placeholders.none'), value: '' },
    ...availableRegions.map(v => ({
      label: __(`muscles.region.${v}`),
      value: v,
    })),
  ];

  const [nameFilter, setNameFilter] = useState('');
  const [sideFilter, setSideFilter] = useState<{ label: string; value: string } | null>(
    defaultSide ? sideSelectOptions.find(v => v.value === defaultSide) ?? null : null,
  );
  const [regionFilter, setRegionFilter] = useState<{ label: string; value: string } | null>(null);

  const filteredMuscles = deviceMuscles
    .filter(v => !sideFilter?.value || v.split('.').at(1) === sideFilter.value)
    .filter(v => !regionFilter?.value || v.startsWith(regionFilter.value + '.'))
    .filter(
      v =>
        !nameFilter ||
        __(`muscles.${getMuscleName(v)}`)
          .toLocaleLowerCase()
          .includes(nameFilter.toLocaleLowerCase()),
    );

  useEffect(() => {
    if (channelToMuscleMap) {
      if (Object.keys(channelToMuscleMap).length === 0) {
        setCalibrationData({ channelToMuscle: undefined });
        return;
      }

      for (const channel in channelToMuscleMap) {
        if (!cableChannels.includes(+channel)) {
          delete channelToMuscleMap[+channel as Channel];
        }
      }
    }
  }, [cableChannels, channelToMuscleMap, setCalibrationData]);

  const getAvailableChannel = useCallback(
    (muscle: MuscleId, availableChannels: number[]) => {
      const availableChannel = availableChannels[0] ?? null;
      const isInternalChannel = internalElectrodes.some(ch => ch.channel === availableChannel);
      const isPelvicMuscle = Object.keys(muscleExtras).includes(muscle);

      if (!isPelvicMuscle && isInternalChannel) {
        // Available channel is internal but muscle is not pelvic, we cannot return it
        // We assume that internal channels are last, so we do not need to search for other
        // available channel.
        return null;
      }

      if (isPelvicMuscle && !isInternalChannel) {
        // Muscle is pelvic but channel available is not internal, so we must find an availale internal channel
        const firstInternalChannel = availableChannels.find(ch => {
          const foundInternalChannel = internalElectrodes.find(i => i.channel === ch);
          if (foundInternalChannel) {
            const isElectrodeInUse = Object.keys(channelToMuscleMap ?? {}).includes(`${foundInternalChannel.channel}`);
            if (!isElectrodeInUse) {
              return foundInternalChannel;
            }
          }
        });

        return firstInternalChannel ?? null;
      }

      return availableChannel;
    },
    [channelToMuscleMap, internalElectrodes],
  );

  const addMuscleFromChannel = useCallback(
    (muscle: MuscleId) => {
      const [startChannel, channelCount] = extractChannelCount(muscle);
      const channelToMuscle = { ...channelToMuscleMap };
      let newAvailableChannels = availableChannels.slice(0);

      if (channelCount > 1) {
        const baseMuscle = muscle.split('.').slice(0, -1).join('.');
        for (let i = 0; i < channelCount; i++) {
          const availableChannel = getAvailableChannel(muscle, newAvailableChannels);

          if (availableChannel === null) {
            return;
          }

          channelToMuscle[availableChannels[i] as Channel] = `${baseMuscle}.channel${i + startChannel}` as MuscleId;
          newAvailableChannels = newAvailableChannels.filter(ch => ch !== availableChannel);
        }
      } else {
        const availableChannel = getAvailableChannel(muscle, newAvailableChannels);

        if (availableChannel === null) {
          return;
        }

        channelToMuscle[availableChannel as Channel] = muscle;
        newAvailableChannels = newAvailableChannels.filter(ch => ch !== availableChannel);
      }

      setAvailableChannels(newAvailableChannels);
      setCalibrationData({ channelToMuscle });
    },
    [availableChannels, channelToMuscleMap, getAvailableChannel, setCalibrationData],
  );

  const removeMuscleFromChannel = useCallback(
    (muscle: MuscleId) => {
      if (!channelToMuscleMap) {
        return;
      }

      const newChannels = [...availableChannels];
      const [startChannel, channelCount] = extractChannelCount(muscle);
      const newMap = { ...channelToMuscleMap };

      if (channelCount > 1) {
        const baseMuscle = muscle.split('.').slice(0, -1).join('.');
        for (let i = 0; i < channelCount; i++) {
          const muscle = `${baseMuscle}.channel${i + startChannel}`;
          const channel = findChannelWithMuscle(newMap, muscle);

          if (channel === null) {
            throw new Error(`Could not find '${muscle}' in channels map`);
          }

          newChannels.push(channel);
          delete newMap[channel as Channel];
        }
      } else {
        const channel = findChannelWithMuscle(newMap, muscle);

        if (channel === null) {
          throw new Error(`Could not find '${muscle}' in channels map`);
        }

        newChannels.push(channel);
        delete newMap[+channel as Channel];
      }

      newChannels.sort((a, b) => a - b);
      setAvailableChannels(newChannels);

      if (Object.keys(newMap).length === 0) {
        setCalibrationData({ channelToMuscle: undefined });
        return;
      }
      setCalibrationData({ channelToMuscle: newMap });
    },
    [availableChannels, channelToMuscleMap, setCalibrationData],
  );

  const canAddMoreInternalElectrode = useMemo(() => {
    return availableChannels.find(ch => internalElectrodes.some(i => i.channel === ch));
  }, [availableChannels, internalElectrodes]);

  return (
    <Container
      variant="calibrationFlowMainWrapper"
      flexDirection="column"
      py={{ base: '3.5', '2xl': 5 }}
      pl={{ base: 14, '2xl': 24 }}
      pr={{ base: 14, '2xl': 20 }}
      height="full"
      gap="5"
    >
      <CalibrationFlowHeader
        flow={flow}
        title="calibrationFlow.headers.channelMuscleSelector"
        minRequiredChannels={minRequiredChannels}
        requiredPelvicChannel={exerciseNeedsPelvicMuscle}
      />
      <Grid pr="4" gap="5" templateColumns="repeat(3, 1fr)" alignItems="flex-end">
        <GridItem>
          <TranslateText
            variant={['openSans16', 'openSans18', 'openSans18', 'openSans20']}
            text={`training.body`}
            color="gray.600"
            mb="1"
          />
          <MainSearchSelect
            placeholder="placeholders.select"
            isSearchable={false}
            value={regionFilter}
            onChange={v => (v?.value ? setRegionFilter(v) : setRegionFilter(null))}
            options={regionSelectOptions}
            width="100%"
          />
        </GridItem>
        <GridItem>
          <TranslateText
            variant={['openSans16', 'openSans18', 'openSans18', 'openSans20']}
            text={`calibrationFlow.labels.side`}
            color="gray.600"
            mb="1"
          />
          <MainSearchSelect
            placeholder="placeholders.select"
            isSearchable={false}
            value={sideFilter}
            onChange={v => (v?.value ? setSideFilter(v) : setSideFilter(null))}
            options={sideSelectOptions}
            width="100%"
          />
        </GridItem>
        <GridItem>
          <MainInput
            width="100%"
            bg="white"
            placeholder="application.search"
            icon={<SearchIcon />}
            data-testid="patientList.searchInput"
            onChange={v => setNameFilter(v.currentTarget.value)}
          />
        </GridItem>
      </Grid>
      <Grid
        flexGrow="1"
        rowGap="13"
        templateColumns="repeat(5, 1fr)"
        w="full"
        justifyItems="center"
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': {
            width: '0.75rem',
            borderRadius: '0.75rem',
            overflow: 'hidden',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '0.75rem',
          },
        }}
      >
        {filteredMuscles.map(v => {
          const [startChannel, channelCount] = extractChannelCount(v);
          const channels: Channel[] = [];
          if (channelCount > 1) {
            const baseMuscle = v.split('.').slice(0, -1).join('.');
            for (let i = 0; i < channelCount; i++) {
              const fesChannel = findChannelWithMuscle(channelToMuscleMap, `${baseMuscle}.channel${i + startChannel}`);

              if (typeof fesChannel === 'number') {
                channels.push(fesChannel as Channel);
              }
            }
          } else {
            const channelWithMuscle = findAllChannelsWithMuscle(channelToMuscleMap, v);

            if (channelWithMuscle) {
              channels.push(...channelWithMuscle);
            }
          }
          return (
            <GridItem key={v}>
              <Flex
                flexDirection="column"
                alignItems="center"
                gap="1"
                maxW={{ base: 52, '2xl': 80 }}
                cursor="pointer"
                onClick={() => {
                  setMuscleChangeDetected(true);

                  if (channels.length > 0) {
                    removeMuscleFromChannel(v);
                    return;
                  }
                  if (availableChannels.length <= 0) {
                    return;
                  }
                  addMuscleFromChannel(v);
                }}
                data-testid={'muscle-image-' + v}
                pt="2"
              >
                <ChannelMuscleImage
                  muscleId={v}
                  type="muscle"
                  boxSize={{ base: 52, '2xl': 80 }}
                  electrodes={isEMSExerciseDefinition(definition) ? 'ems' : 'emg'}
                  channels={channels}
                  maxChannels={muscleExtras[v]?.maxChannels ?? 1}
                  hasBorder={channels.length > 0}
                  canAddMore={!!canAddMoreInternalElectrode}
                  onAddMore={() => {
                    setMuscleChangeDetected(true);

                    if (availableChannels.length <= 0) {
                      return;
                    }

                    addMuscleFromChannel(v);
                  }}
                />
                <TranslateText
                  text={`muscles.${getMuscleName(v)}`}
                  variant="openSans20"
                  wordBreak="break-word"
                  textAlign="center"
                />
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    </Container>
  );
};
