import { FC } from 'react';
import { Button, Container } from '@chakra-ui/react';
import Polyglot from 'node-polyglot';

import { DelIcon } from 'components/icons';

import { Error, Info, Success, Update, Warning } from './AlertType';
import { toastStatuses } from '.';

export type extendedToastStatuses = toastStatuses | 'update';

interface Props {
  isClosableByX?: boolean;
  isClosableByClick?: boolean;
  onClose?: () => void;
  title: string;
  subtitle?: string;
  status: extendedToastStatuses;
  replace?: Polyglot.InterpolationOptions;
}

export const Alert: FC<Props> = ({
  isClosableByX = true,
  isClosableByClick = false,
  onClose,
  title,
  subtitle,
  status,
  replace,
}) => {
  const chooseToast = (status: extendedToastStatuses) => {
    switch (status) {
      case 'success':
        return <Success title={title} subtitle={subtitle} replace={replace} />;
      case 'error':
        return <Error title={title} subtitle={subtitle} replace={replace} />;
      case 'warning':
        return <Warning title={title} subtitle={subtitle} replace={replace} />;
      case 'update':
        return <Update title={title} subtitle={subtitle} replace={replace} />;
      default:
        return <Info title={title} subtitle={subtitle} replace={replace} />;
    }
  };

  return (
    <Container
      variant="alert"
      data-test={'notification.' + status}
      cursor={isClosableByClick ? 'pointer' : 'auto'}
      onClick={() => isClosableByClick && onClose?.()}
    >
      {chooseToast(status)}
      {isClosableByX && (
        <Button onClick={onClose} variant="flatIconButton" color="gray.300" alignSelf="flex-start" mt="0.5">
          <DelIcon fontSize="xs" />
        </Button>
      )}
    </Container>
  );
};
