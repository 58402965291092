import { DeepReadonly, EventBus, ExoSessionStatus } from '@egzotech/exo-session';
import { CableType, ChannelConnectionQuality } from '@egzotech/exo-session/features/cable';
import { Logger } from '@egzotech/universal-logger-js';
import { Signal } from 'helpers/signal';
import { ContextSensorAngleData } from 'views/+patientId/training/+trainingId/_containers/ExerciseCalibrationFlow';

import {
  addSummaryToFlow,
  CalibrationFlowConditionKey,
  CalibrationFlowDefinitionStateData,
  CalibrationFlowDefinitionStates,
  CalibrationProgramFlowDefinition,
  getCalibrationFlowStateDataDefinition,
  replayableSteps,
} from '../calibrations';
import {
  CableStatus,
  DeviceType,
  exerciseActionTracker,
  ExtensionType,
  GeneratedExerciseDefinition,
  MotorPlacement,
} from '..';

export type CalibrationFlowStateIdentifier =
  | 'channel-muscle-selector' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/65a502882140cf9f0e326ce4 (Przypisz mięśnie do kanałów)
  | 'channel-role-selector' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/65a50285aee535c67376767d (Przypisz rolę do kanałów)
  | 'connect-electrodes'
  | 'emg-calibration' // https://xd.adobe.com/view/6ba6a41f-33b1-4c92-91f4-7941f1b7efa2-3ef5/screen/08a7fb9e-97a8-4d6f-a631-07d088d414a6/ (Kalibracja EMG - 12)
  | 'force-calibration'
  | 'ems-calibration'
  | 'ems-warnings'
  | 'connect-cable'
  | 'attach-extension'
  | 'detach-extension' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6641ae2ab0e7b33d095452ed
  | 'leg-basing-leg-side-selection' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646353e6b4697024f1a00bd4
  | 'leg-basing-set-thigh-length' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/63fe09fb15721b4104e33b6e (Ustaw szynę względem długości uda)
  | 'leg-basing-set-safe-range' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/63fe09fbfff004400ba8a0fc (Wybierz swój bezpoeczny zakres ROM kolano)
  | 'leg-basing-set-safe-range-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/63fe09fbfff004400ba8a0fc (Wybierz swój bezpoeczny zakres ROM kolano)
  | 'leg-basing-move-into-range-conditional-knee-after-safe-range' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-move-into-range-conditional-knee-before-passive-range' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-move-into-range-conditional-knee-before-unweight' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-set-safe-range-ankle' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b6365cdd58827ff488dab
  | 'leg-basing-move-into-range-conditional-ankle-after-safe-range' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-move-into-range-conditional-ankle-before-passive-range' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-move-into-range-conditional-ankle-before-unweight' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-you-can-safely-place-leg' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6422252c2b57ee2256b42615 (Teraz możesz bezpiecznie umieścić nogę w szynie)
  | 'leg-basing-adjust-length-of-the-splint' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/645a18cd1d4c8c25851764f2 (Dostosuj długość szyny do podudzia)
  | 'leg-basing-adjust-foot-to-tip' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/645a18d06cec942602091849 (Dopasuj stopę do końcówki)
  | 'leg-basing-check-bend-points' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646376ac3f26722526b6cf9b (Sprawdź czy punkty zgięcia są na poprawnych pozycjach)
  | 'leg-basing-max-flexion-and-extension-measurement-passive-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646376ac3f26722526b6cf9b (Pomiar maksymalnego zgięcia / wyprostu kolana - bierne)
  | 'leg-basing-move-middle-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b41af396600283622f631 (Przejazd do środka zakresu)
  | 'leg-basing-max-flexion-and-extension-measurement-passive-ankle' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222533b2d4452229410e6d (Pomiar maksymalnego zgięcia / wyprostu kostki - bierne)
  | 'leg-basing-limb-unweight' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/63d78c82c60214124a0d4446 (Odciążenie kończyny)
  | 'leg-basing-speed-measurement-extension-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b2b02f4c3ae27d25aa451,
  | 'leg-basing-speed-measurement-extension-main'
  | 'leg-basing-speed-measurement-extension-ankle'
  | 'leg-basing-speed-measurement-flexion-knee' //https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646b2b06725cf8280a839c5f
  | 'leg-basing-speed-measurement-flexion-main'
  | 'leg-basing-speed-measurement-flexion-ankle'
  | 'leg-basing-max-flexion-and-extension-measurement-active-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646376bd7622d82f5ccde4bb (Pomiar maksymalnego zgięcia / wyprostu kolana - czynne)
  | 'leg-basing-max-flexion-and-extension-measurement-active-ankle' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/646376bd7622d82f5ccde4bb (Pomiar maksymalnego zgięcia / wyprostu kolana - czynne)
  | 'leg-basing-move-beginning-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222530f3e8d721fff192ac
  | 'leg-basing-move-center-knee'
  | 'leg-basing-move-end-knee' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222530f3e8d721fff192ac
  | 'leg-basing-move-beginning-knee-speed-measurement' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222530f3e8d721fff192ac
  | 'leg-basing-move-beginning-ankle-speed-measurement' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222530f3e8d721fff192ac
  | 'leg-basing-move-end-knee-speed-measurement' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222530f3e8d721fff192ac
  | 'leg-basing-move-end-ankle-speed-measurement'
  | 'leg-basing-move-beginning-ankle' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64222538d70beb21e93412aa
  | 'leg-basing-move-center-ankle'
  | 'leg-basing-move-end-ankle' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64c3a6b17002f3229b3e2dc4
  | 'leg-basing-extension-torque-knee'
  | 'leg-basing-flexion-torque-knee'
  | 'leg-basing-extension-torque-ankle'
  | 'leg-basing-flexion-torque-ankle'
  | 'basing-settings' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6498eef1bdaede2305e76063 (Ustaw parametry)
  | 'basing-summary' //https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/64ff2ad43f766f225cf76ebf
  | 'meissa-basing-side-selection' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6539073b4448b9227a264aa9
  | 'meissa-basing-set-correct-position' //https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/657b54aec57efb1ff805c1a4
  | 'meissa-relief-device' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6641ae2711e8b655a43f4f8d
  | 'meissa-basing-range-of-movement' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6539072cc8a32a22b2e7325b
  | 'meissa-counter-clockwise-torque' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6539070fd6dcdb2238ff4ec7
  | 'meissa-clockwise-torque' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/653906f97507d922598c03fd
  | 'meissa-move-into-center-before-passive-range' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/653a6f712e9a0b1fe12ee537
  | 'meissa-move-into-center-before-game' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/653a6f712e9a0b1fe12ee537
  | 'meissa-extension-unweight' //https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/6641ae226837e73f9673b6fb
  | 'meissa-move-end'
  | 'meissa-spasticism-armrest'
  | 'meissa-limb-unweight'
  | 'game-select-level' // https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/66029f1bf253ad00347e157b
  | 'game-select-control' //https://app.zeplin.io/project/63b6af170e11be670c53f069/screen/66029f1bd5057919847cdfbe
  | 'meissa-relief-correct-position'
  | 'emg-steps';

export type AlertId = 'cableDetached' | 'disconnectedElectrode' | 'deviceDisconnected' | 'extensionDetached';

type CalibrationFlowEvents = {
  // triggered when the state is changed and stateId is a NEW state id.
  stateChange: (stateId: CalibrationFlowStateIdentifier | null) => void;
  stateDataChange: (data: object | null, changedState: CalibrationFlowStateIdentifier | null) => void;
  canProceedChange: (canProceed: boolean) => void;
  canGoBackChange: (canGoBack: boolean) => void;
  finish: (exportedStateData: CalibrationFlowStatesData) => void;
  alert: (alert: AlertId) => void;
  exit: () => void;
};

export type CalibrationFlowStatesData = { [key in CalibrationFlowStateIdentifier]?: Record<string, unknown> };
export type CalibrationFlowStatesTypedData = {
  [key in CalibrationFlowStateIdentifier]?: CalibrationFlowDefinitionStateData<key>;
};

export interface ExoClinicCalibrationFlowContext {
  isRepeated: boolean;
  motors: { [key in MotorPlacement]?: Signal<number> };
  extension: Signal<{
    type: ExtensionType;
    features: readonly string[];
  } | null>;
  cableStatus: Signal<CableStatus>;
  channelsConnectionQuality: Signal<Record<number, ChannelConnectionQuality> | null>;
  connectionStatus: Signal<ExoSessionStatus>;
  sensorAngleData: Signal<ContextSensorAngleData | null>;
  meissaWasTared: Signal<boolean>;
  selectedProgram: GeneratedExerciseDefinition;
  deviceType: DeviceType;
  selectedCableType: Signal<DeepReadonly<CableType> | undefined>;
}

export function filterStoredCalibrationDataForReplayableSteps(
  storedCalibrationData: CalibrationFlowStatesTypedData,
  exerciseIsRepeated: boolean,
  definedCalibrationFlowSteps?: CalibrationFlowDefinitionStates,
) {
  let calibrationData = storedCalibrationData;

  if (definedCalibrationFlowSteps) {
    // Stored calibrationData is filtered by steps which are in currentExercise calibrationFlow definition
    calibrationData = Object.fromEntries(
      Object.entries(storedCalibrationData)
        .filter(
          ([key]) => key === 'basing-settings' || definedCalibrationFlowSteps[key as CalibrationFlowStateIdentifier],
        )
        .map(([key, value]) => [key, value]),
    ) as CalibrationFlowStatesTypedData;
  }

  const filteredData: CalibrationFlowStatesData = {};

  const filteredStepsWhenExerciseNotRepeated = Object.fromEntries(
    Object.entries(calibrationData).filter(
      ([key]) => !replayableSteps[key as CalibrationFlowStateIdentifier]?.onlySkippableIfRepeated,
    ),
  );

  const currentStoredCalibrationData = exerciseIsRepeated ? calibrationData : filteredStepsWhenExerciseNotRepeated;

  for (const stepId of Object.keys(replayableSteps) as CalibrationFlowStateIdentifier[]) {
    if (currentStoredCalibrationData[stepId]) {
      filteredData[stepId as CalibrationFlowStateIdentifier] = {
        ...currentStoredCalibrationData[stepId],
      };
    }
  }
  return filteredData as CalibrationFlowStatesTypedData;
}

export class CalibrationFlow<T = ExoClinicCalibrationFlowContext> {
  private calibrationFlow: CalibrationProgramFlowDefinition;
  private _currentState: CalibrationFlowStateIdentifier | null;
  private _stateData: CalibrationFlowStatesData;
  private _context: T;
  private _canProceed: boolean;
  private _canGoBack: boolean;
  private _conditionHandlers: Record<string, (flow: CalibrationFlow<T>, param?: any) => boolean>;
  private _onConditionHandlersCallback: Record<string, (flow: CalibrationFlow<T>) => void>;

  private _moveFromStep: 'prev' | 'next' | null = null;

  static readonly logger = Logger.getInstance('CalibrationFlow');

  readonly events: EventBus<CalibrationFlowEvents> = new EventBus();

  storedCalibrationDataForCurrentFlow: CalibrationFlowStatesTypedData | null = null;

  interrupted: AlertId | false;

  get context() {
    return this._context;
  }

  constructor(
    definition: CalibrationProgramFlowDefinition,
    context: T,
    conditionHandlers: Record<string, (flow: CalibrationFlow<T>, param?: any) => boolean>,
    onConditionHandlersCallback: Record<string, (flow: CalibrationFlow<T>) => void>,
    storedCalibrationData: CalibrationFlowStatesTypedData | null,
  ) {
    this.calibrationFlow = structuredClone(definition);
    this._context = context;

    this._currentState = this.calibrationFlow.initial;
    this._stateData = structuredClone({ ...definition.initialStates, ...storedCalibrationData }) ?? {};
    this.interrupted = false;
    this._canProceed = false;
    this._canGoBack = false;
    this._conditionHandlers = conditionHandlers;
    this._onConditionHandlersCallback = onConditionHandlersCallback;

    const isRepeated = (context as { isRepeated: boolean }).isRepeated;

    if (storedCalibrationData) {
      this.storedCalibrationDataForCurrentFlow = filterStoredCalibrationDataForReplayableSteps(
        storedCalibrationData,
        isRepeated,
      );

      if (Object.keys(this.storedCalibrationDataForCurrentFlow).length > 0) {
        addSummaryToFlow(this.calibrationFlow);
        this._currentState = this.calibrationFlow.initial;
      }
    }

    this.checkConditions();

    while (this._currentState) {
      if (this.canSkipCurrentState()) {
        this._currentState = this.calibrationFlow.states[this._currentState]?.next ?? null;
      } else {
        break;
      }
    }
  }

  /**
   * Apply changes previously made for current flow
   */
  public applyStoredCalibrationData(options: { setSkippable: boolean; exerciseIsRepeated: boolean }) {
    if (this.storedCalibrationDataForCurrentFlow && Object.keys(this.storedCalibrationDataForCurrentFlow).length > 0) {
      for (const stateId of Object.keys(this.calibrationFlow.states) as CalibrationFlowStateIdentifier[]) {
        const state = this.storedCalibrationDataForCurrentFlow?.[stateId];
        if (state) {
          if (replayableSteps[stateId]?.skippable) {
            if (replayableSteps[stateId]?.onlySkippableIfRepeated && !options.exerciseIsRepeated) return;
            const dependant = replayableSteps[stateId]?.dependant;
            if (dependant && this.calibrationFlow.states[dependant]) {
              this.calibrationFlow.states[dependant]!.skippable = options.setSkippable;
            }
            this.calibrationFlow.states[stateId]!.skippable = options.setSkippable;
          }
        }
      }
    }

    EventBus.raise(this.events, 'stateDataChange', { ...this.stateData }, null);
  }

  get currentState() {
    return this._currentState;
  }

  get finished() {
    return !this._currentState;
  }

  get definition() {
    return this.calibrationFlow;
  }

  get prevState() {
    return this._currentState ? this.calibrationFlow.states[this._currentState]?.prev ?? null : null;
  }

  get nextState() {
    return this._currentState ? this.calibrationFlow.states[this._currentState]?.next ?? null : null;
  }

  get canGoBack() {
    return this._canGoBack;
  }

  private set canGoBack(value: boolean) {
    const hasChanged = this._canGoBack !== value;
    this._canGoBack = value;
    hasChanged && EventBus.raise(this.events, 'canGoBackChange', value);
  }

  get stateData() {
    return this._stateData;
  }

  get canProceed() {
    return this._canProceed;
  }

  private set canProceed(value: boolean) {
    const hasChanged = this._canProceed !== value;
    this._canProceed = value;
    hasChanged && EventBus.raise(this.events, 'canProceedChange', value);
  }

  setStateData<T extends CalibrationFlowStateIdentifier>(
    stateData: CalibrationFlowDefinitionStateData<T>,
    state?: T,
  ): void;
  setStateData(stateData: Record<string, unknown>, state?: CalibrationFlowStateIdentifier): void;
  setStateData(stateData: Record<string, unknown>, state = this._currentState): void {
    if (!state) {
      throw new Error('The flow has ended and more state data cannot be added.');
    }

    this._stateData[state] = Object.assign(this._stateData[state] ?? {}, stateData);
    this.checkConditions();

    EventBus.raise(this.events, 'stateDataChange', { ...this._stateData }, state);
  }

  /**
   * This method is designed to retrieve state data outside from React. In React components you should use hook [`useCalibrationFlowState`](../../react/hooks/useCalibrationFlowState.ts)
   */
  getStateData<T extends CalibrationFlowStateIdentifier>(state?: T): CalibrationFlowDefinitionStateData<T> | null;
  getStateData(state?: CalibrationFlowStateIdentifier | null): Record<string, unknown> | null;
  getStateData(state: CalibrationFlowStateIdentifier | null = this._currentState): Record<string, unknown> | null {
    if (!state) {
      throw new Error(
        'The flow has ended and current state cannot be determined automatically. Please specify a state manually.',
      );
    }

    return this._stateData[state] ?? null;
  }

  /**
   * Remove exclamation mark from beginning of the condition name (if exists)
   *
   * @param condition - The condition name to verify
   * @returns condition name without exclamation mark
   */

  private strippedConditionName(name: string | undefined) {
    if (!name) {
      return;
    }
    if (name.startsWith('!')) {
      return name.substring(1);
    }

    return name;
  }

  /**
   * Return information about step from which from are you moving from
   * @returns "prev" | "next" | null
   */
  get moveFromStep() {
    return this._moveFromStep;
  }

  /**
   * Checks if given condition is met.
   * The condition can be negative if an exclamation mark precedes it
   *
   * @param condition - The condition to check
   * @returns true if the condition is met.
   */
  private isConditionMet(condition: CalibrationFlowConditionKey) {
    const match = condition.match(/^!(.*)$/);

    if (match) {
      return !this._conditionHandlers[match[1]](this);
    }
    return this._conditionHandlers[condition](this);
  }

  private canSkipCurrentState() {
    if (!this._currentState) {
      throw new Error('The flow has ended and there are no more steps.');
    }

    // Check if step can be skipped due to condition handler
    const stateCondition =
      this.strippedConditionName(this.calibrationFlow.states[this._currentState]?.condition) ?? null;

    if (stateCondition) {
      if (
        this._conditionHandlers[stateCondition](
          this,
          this._currentState ? this.calibrationFlow.states[this._currentState]?.conditionParam : undefined,
        )
      ) {
        if (this._onConditionHandlersCallback[stateCondition]) {
          this._onConditionHandlersCallback[stateCondition](this);
        }
        return true;
      }
    }

    this.checkConditions();

    // Check if step can be skipped due to historic data flow
    if (this.canProceed && this.calibrationFlow.states[this._currentState]!.skippable) {
      return true;
    }

    return false;
  }

  next() {
    if (!this._currentState) {
      throw new Error('The flow has ended and there are no more steps.');
    }

    const currentState = this.calibrationFlow.states[this._currentState];

    if (!currentState) {
      throw new Error(`Cannot find a state in the flow named ${this._currentState}`);
    }
    this._moveFromStep = 'prev';

    if (!this.canProceed) {
      throw new Error('Cannot proceed to the next state because checks are no fulfilled.');
    }
    this._currentState = currentState.next;

    while (this._currentState) {
      if (this.canSkipCurrentState()) {
        EventBus.raise(this.events, 'stateChange', this._currentState);
        this._currentState = this.calibrationFlow.states[this._currentState]?.next ?? null;
      } else {
        break;
      }
    }
    if (this._currentState === null) {
      exerciseActionTracker.timePoints.calibrationFlow.end = new Date();
      CalibrationFlow.logger.debug('next', 'Flow has been finished');
      EventBus.raise(this.events, 'stateChange', this._currentState);
      EventBus.raise(this.events, 'finish', this.export());
      return;
    }
    this.checkConditions();
    EventBus.raise(this.events, 'stateChange', this._currentState);
  }

  previous() {
    if (!this._currentState) {
      throw new Error('The flow has ended and it cannot go back.');
    }

    const currentState = this.calibrationFlow.states[this._currentState];

    if (!currentState) {
      throw new Error(`Cannot find a state in the flow named ${this._currentState}`);
    }
    this._moveFromStep = 'next';

    this._currentState = currentState.prev;

    while (this._currentState) {
      if (this.canSkipCurrentState()) {
        EventBus.raise(this.events, 'stateChange', this._currentState);
        this._currentState = this.calibrationFlow.states[this._currentState]?.prev ?? null;
      } else {
        break;
      }
    }

    if (this._currentState === null) {
      CalibrationFlow.logger.info(
        'previous',
        `Current state does not have a previous step. Exiting from calibration flow.`,
      );
      EventBus.raise(this.events, 'stateChange', this._currentState);
      EventBus.raise(this.events, 'exit');
      return;
    }

    this.checkConditions();
    EventBus.raise(this.events, 'stateChange', this._currentState);
  }

  reset() {
    this._currentState = this.calibrationFlow.initial;
    this._stateData = {};
    this._canProceed = false;

    for (const stepId in this.calibrationFlow.states) {
      const typedStepId = stepId as CalibrationFlowStateIdentifier;
      this.calibrationFlow.states[typedStepId]!.skippable = false;
    }

    this.checkConditions();

    CalibrationFlow.logger.debug('reset', 'Reset calibration flow');

    EventBus.raise(this.events, 'stateChange', this._currentState);
    EventBus.raise(this.events, 'stateDataChange', {}, null);
  }

  getCurrentStepIndex() {
    if (!this._currentState) {
      throw new Error('The flow has ended and it cannot go back.');
    }

    const currentState = this.calibrationFlow.states[this._currentState];

    if (!currentState) {
      throw new Error(`Cannot find a state in the flow named ${this._currentState}`);
    }

    if (!currentState.stepIndex) {
      CalibrationFlow.logger.debug('getCurrentStepIndex', `Current state does not have a stepIndex`);
      return null;
    }
    return currentState.stepIndex;
  }

  getStepsNumber() {
    return this.calibrationFlow.stepsNumber;
  }

  export(): CalibrationFlowStatesData {
    return structuredClone(this._stateData);
  }

  resolveAlert() {
    if (!this.interrupted) {
      throw new Error('There is no alert to resolve');
    }
    const alert = this.calibrationFlow.alerts?.[this.interrupted];
    if (alert?.type === 'warning') {
      this._currentState = alert.resolveState;
    }
    this.checkConditions();
    this.interrupted = false;
    EventBus.raise(this.events, 'stateChange', this._currentState);
  }

  checkConditions() {
    if (!this._currentState) {
      throw new Error('The flow has ended and conditions cannot be checked.');
    }

    const currentState = this.calibrationFlow.states[this._currentState];

    if (!currentState) {
      throw new Error(`Cannot find a state in the flow named ${this._currentState}`);
    }

    this.canGoBack = currentState.prev !== null;

    let result = true;

    Object.entries(this.calibrationFlow.alerts).forEach(([key, alertDef]) => {
      const alertId = key as AlertId;
      if (
        !this.calibrationFlow.states[this._currentState!]?.disableAlerts?.includes(alertId) &&
        this.isConditionMet(alertDef.condition)
      ) {
        result = false;
        if (!this.interrupted) {
          this.interrupted = alertId;
        }

        EventBus.raise(this.events, 'alert', this.interrupted);
      }
    });

    const definition = getCalibrationFlowStateDataDefinition(this._currentState);

    if (!definition) {
      this.canProceed = result;
      return;
    }

    const stateData = this._stateData[this._currentState] ?? {};

    if (!stateData) {
      this.canProceed = false;
      return;
    }

    for (const prop in definition) {
      const conditions = definition[prop];
      let value = stateData[prop];

      if (conditions.computed) {
        value = this._conditionHandlers[conditions.computed](this, conditions.computedParam);
      }

      if (conditions.exists && !(prop in stateData)) {
        result = false;
        break;
      }

      if (conditions.array && !Array.isArray(value)) {
        result = false;
        break;
      }

      if (!conditions.array && conditions.type && typeof value !== conditions.type) {
        result = false;
        break;
      }

      if ('equals' in conditions && value !== conditions.equals) {
        result = false;
        break;
      }

      if (conditions.regex && !`${value}`.match(conditions.regex)) {
        result = false;
        break;
      }

      if (conditions.min) {
        if (typeof value !== 'number') {
          result = false;
          break;
        } else if (value < conditions.min && !conditions.minExclusive) {
          result = false;
          break;
        } else if (value <= conditions.min && conditions.minExclusive) {
          result = false;
          break;
        }
      }
      if (conditions.max) {
        if (typeof value !== 'number') {
          result = false;
          break;
        } else if (value > conditions.max && !conditions.maxExclusive) {
          result = false;
          break;
        } else if (value >= conditions.max && conditions.maxExclusive) {
          result = false;
          break;
        }
      }
    }

    this.canProceed = result;
  }
}
