import { maxDeviceRangeAnkle, minDeviceRangeAnkle, rangeTolerances } from 'config/defaultConfigProps';
import { EGZOTechHostApi } from 'services/EGZOTechHostApi';

import { CalibrationFlowDefinitionStateDataDefinition } from './types';

function extendIf<T>(obj: T, condition: boolean): T {
  return condition ? obj : ({} as T);
}

// This object controls what conditions must be met to allow the state to continue to the next step
export const calibrationFlowStateDataDefinitions = {
  'game-select-level': {
    level: {
      type: 'number',
      exists: true,
    },
  },
  'game-select-control': {
    control: {
      type: 'string',
      exists: true,
    },
  },
  'leg-basing-leg-side-selection': {
    sideSelection: {
      type: 'string',
      exists: true,
    },
  },
  'leg-basing-set-thigh-length': {
    thighLength: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-set-safe-range-knee': {
    isRangeWithinDeviceRange: {
      computed: 'isRangeWithinDeviceRange',
      equals: true,
      computedParam: {
        motorName: 'knee',
      },
    },
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      exists: true,
    },
    max: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-set-safe-range-ankle': {
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      max: maxDeviceRangeAnkle,
      min: minDeviceRangeAnkle,
      exists: true,
    },
    max: {
      type: 'number',
      max: maxDeviceRangeAnkle,
      min: minDeviceRangeAnkle,
      exists: true,
    },
  },
  'leg-basing-max-flexion-and-extension-measurement-passive-knee': {
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      exists: true,
    },
    max: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-max-flexion-and-extension-measurement-passive-ankle': {
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      exists: true,
    },
    max: {
      type: 'number',
      exists: true,
    },
  },
  'meissa-basing-range-of-movement': {
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      exists: true,
    },
    max: {
      type: 'number',
      exists: true,
    },
  },
  'meissa-basing-side-selection': {
    sideSelection: {
      type: 'string',
      exists: true,
    },
  },
  'leg-basing-speed-measurement-extension-knee': {
    measurements: {
      array: true,
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-speed-measurement-flexion-knee': {
    measurements: {
      array: true,
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-speed-measurement-extension-ankle': {
    measurements: {
      array: true,
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-speed-measurement-flexion-ankle': {
    measurements: {
      array: true,
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-move-into-range-conditional-knee-after-safe-range': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
      equals: true,
    },
  },
  'leg-basing-move-into-range-conditional-knee-before-passive-range': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
      equals: true,
    },
  },
  'leg-basing-move-into-range-conditional-knee-before-unweight': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
      equals: true,
    },
  },
  'leg-basing-move-into-range-conditional-ankle-after-safe-range': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
    },
  },
  'leg-basing-move-into-range-conditional-ankle-before-passive-range': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
    },
  },
  'leg-basing-move-into-range-conditional-ankle-before-unweight': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
    },
  },
  'leg-basing-move-middle-knee': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['sidra-leg'],
      },
    },
  },
  'meissa-move-into-center-before-passive-range': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['meissa-ot'],
      },
    },
  },
  'meissa-move-end': {
    isInEndRange: {
      computed: 'isInEndRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['meissa-ot'],
      },
    },
  },
  'meissa-move-into-center-before-game': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        tolerance: rangeTolerances['meissa-ot'],
      },
    },
  },
  'meissa-relief-correct-position': {
    startConditionalFnValidation: {
      computed: 'isInReliefCorrectPosition',
      equals: true,
    },
  },
  'meissa-relief-device': {
    skipRelief: {
      computed: 'skipRelief',
    },
  },
  'meissa-extension-unweight': {
    limbUnweighted: {
      type: 'boolean',
      exists: true,
    },
    skipTare: {
      computed: 'skipTare',
    },
  },
  'leg-basing-move-beginning-knee': {
    isInBeginningRange: {
      computed: 'isInBeginningRange',
      equals: true,
      computedParam: {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-knee',
      },
    },
  },
  'leg-basing-move-center-knee': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-knee',
        tolerance: rangeTolerances['sidra-leg'],
      },
    },
  },
  'leg-basing-move-center-ankle': {
    isInMiddleRange: {
      computed: 'isInMiddleRange',
      equals: true,
      computedParam: {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-passive-ankle',
        tolerance: rangeTolerances['sidra-leg'],
      },
    },
  },
  'leg-basing-move-beginning-knee-speed-measurement': {
    isInBeginningRange: {
      computed: 'isInBeginningRange',
      equals: true,
      computedParam: {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-knee',
      },
    },
  },
  'leg-basing-move-end-knee': {
    isInEndRange: {
      computed: 'isInEndRange',
      equals: true,
      computedParam: {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-knee',
      },
    },
  },
  'leg-basing-move-end-knee-speed-measurement': {
    isInEndRange: {
      computed: 'isInEndRange',
      equals: true,
      computedParam: {
        motorName: 'knee',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-knee',
      },
    },
  },
  'leg-basing-move-beginning-ankle': {
    isInBeginningRange: {
      computed: 'isInBeginningRange',
      equals: true,
      computedParam: {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-ankle',
      },
    },
  },
  'leg-basing-move-beginning-ankle-speed-measurement': {
    isInBeginningRange: {
      computed: 'isInBeginningRange',
      equals: true,
      computedParam: {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-ankle',
      },
    },
  },
  'leg-basing-move-end-ankle': {
    isInEndRange: {
      computed: 'isInEndRange',
      equals: true,
      computedParam: {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-ankle',
      },
    },
  },
  'leg-basing-move-end-ankle-speed-measurement': {
    isInEndRange: {
      computed: 'isInEndRange',
      equals: true,
      computedParam: {
        motorName: 'ankle',
        rangeProviderState: 'leg-basing-max-flexion-and-extension-measurement-active-ankle',
      },
    },
  },
  'leg-basing-limb-unweight': {
    limbUnweighted: {
      type: 'boolean',
      exists: true,
    },
    skipTare: {
      computed: 'skipTare',
    },
  },
  'meissa-limb-unweight': {
    limbUnweighted: {
      type: 'boolean',
      exists: true,
    },
  },
  'meissa-basing-set-correct-position': {
    verticalAngle: {
      type: 'number',
      exists: true,
    },
    ...extendIf(
      {
        horizontalAngle: {
          type: 'number',
          exists: true,
        },
      },
      !EGZOTechHostApi.instance?.options?.meissaOtDisableHorizontalSensor,
    ),
  },
  'leg-basing-max-flexion-and-extension-measurement-active-knee': {
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      exists: true,
    },
    max: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-max-flexion-and-extension-measurement-active-ankle': {
    isRangeHasMinValue: {
      computed: 'isRangeHasMinValue',
      equals: true,
      computedParam: {
        minValue: 1,
      },
    },
    min: {
      type: 'number',
      exists: true,
    },
    max: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-extension-torque-knee': {
    maxExtensionTorque: {
      type: 'number',
      exists: true,
    },
  },
  'meissa-counter-clockwise-torque': {
    maxCounterClockwiseTorque: {
      type: 'number',
      exists: true,
    },
  },
  'meissa-clockwise-torque': {
    maxClockwiseTorque: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-flexion-torque-knee': {
    maxFlexionTorque: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-extension-torque-ankle': {
    maxExtensionTorque: {
      type: 'number',
      exists: true,
    },
  },
  'leg-basing-flexion-torque-ankle': {
    maxFlexionTorque: {
      type: 'number',
      exists: true,
    },
  },
  'channel-muscle-selector': {
    channelToMuscle: {
      type: 'object',
      exists: true,
    },
    areMusclesAssignedCorrectly: {
      computed: 'areMusclesAssignedCorrectly',
      equals: true,
    },
  },
  'channel-role-selector': {
    channelRolesInstances: {
      type: 'object',
      exists: true,
    },
    skipRoleScreen: {
      computed: 'skipRoleScreen',
    },
  },
  'connect-electrodes': {
    connectedChannelsToMuscles: {
      type: 'object',
      exists: true,
      array: true,
    },
    areElectrodesConnected: {
      computed: 'areElectrodesConnected',
      equals: true,
    },
  },
  'ems-calibration': {
    intensitiesInstances: {
      type: 'object',
      exists: true,
    },
    exportedCalibrations: {
      type: 'object',
      exists: true,
    },
  },
  'emg-calibration': {
    mvc: {
      type: 'object',
    },
    threshold: {
      type: 'object',
    },
  },

  'emg-steps': {
    steps: {
      type: 'object',
    },
    defaultStep: {
      type: 'object',
    },
    index: {
      type: 'number',
    },
    isProgramLengthGreaterThanZero: {
      computed: 'isProgramLengthGreaterThanZero',
      equals: true,
    },
  },
  'force-calibration': {
    mvc: {
      type: 'object',
      exists: true,
    },
    threshold: {
      type: 'object',
      exists: true,
    },
  },
  'connect-cable': {
    isCableAttached: {
      computed: 'isCableAttached',
      equals: true,
    },
  },
  'attach-extension': {
    isCorrectExtensionAttached: {
      computed: 'isCorrectExtensionAttached',
      equals: true,
    },
  },
  'detach-extension': {
    isCorrectExtensionAttached: {
      computed: 'isCorrectExtensionAttached',
      equals: false,
    },
  },
  'basing-settings': {
    exerciseParameters: {
      type: 'object',
      exists: true,
    },
    definition: {
      type: 'object',
    },
    // we do not support time or repetition as parameter in games
    // TODO: to consider if this check brings any value
    // verifyRepetitionsAndTime: {
    //   computed: 'verifyRepetitionsAndTime',
    //   equals: true,
    // },
  },
  'basing-summary': {
    changesRequested: {
      type: 'boolean',
      exists: false,
    },
  },
} satisfies CalibrationFlowDefinitionStateDataDefinition;
