import { MuscleId } from 'config/muscles';
import { includesAllStrings, includesAnyString } from 'helpers/string';

import { EMGExerciseDefinition, EMSExerciseDefinition } from '../../types/GeneratedExerciseDefinition';

import { EMSEMGExerciseId } from './ems-emg-exercise';
import { EMSExerciseId } from './ems-exercise';

export const muscleNames = [
  'adductores',
  'adductorMagnus',
  'adductorPollicis',
  'analFemale',
  'analMale',
  'bicepsBrachii',
  'bicepsFemoris',
  'brachioradialis',
  'deltoideusAnterior',
  'deltoideusClavicularPart',
  'deltoideusMedium',
  'deltoideusPosterior',
  'erectorSpinaeIliocostalis',
  'erectorSpinaeLongissimus',
  'extensorCarpiRadialisBrevis',
  'extensorCarpiRadialisLongus',
  'extensorCarpiUlnaris',
  'extensorDigitorum',
  'flexorCarpiRadialis',
  'flexorCarpiUlnaris',
  'flexorDigitorumSuperficialis',
  'flexorPollicisLongus',
  'frontalis',
  'gastrocnemiusCaputLaterale',
  'gastrocnemiusCaputMediale',
  'gastrocnemiusMedialis',
  'gluteusMaximus',
  'gluteusMedius',
  'iliopsoas',
  'infraspinatus',
  'latissimusDorsi',
  'multifidus',
  'neckExtensors',
  'palmarisLongus',
  'pectoralisMajor',
  'pectoralisMajor2',
  'popliteus',
  'pronatorTeres',
  'peroneus',
  'quadricepsFemoris',
  'rectusFemoris',
  'semimembranosus',
  'semitendinosus',
  'serratusAnterior',
  'soleus',
  'sternocleidomastoid',
  'supraspinatus',
  'temporal',
  'masseter',
  'tensorFasciaLatae',
  'tibialisAnterior',
  'transversusAbdominis',
  'trapeziusAscendens',
  'trapeziusDescendes',
  'trapeziusTransversalis',
  'tricepsBrachii',
  'tricepsBrachiiCLat',
  'tricepsBrachiiCLong',
  'obliquusExternusAbdominis',
  'rectusAbdominis',
  'tricepsSuraeCaputLaterale',
  'tricepsSuraeCaputMediale',
  'vastusLateralis',
  'vastusMedialis',
  'peroneus',
  'tensAnkle',
  'tensElbow',
  'tensFoot',
  'tensHand',
  'tensLowBack',
  'tensShoulder',
  'tensWrist',
  'tensUpperBack',
  'tensKnee',
  'tensHip',
  'vagina',
] as const;

export type MuscleName = (typeof muscleNames)[number];

export const FESGroup = ['handToMouth', 'armSupport', 'graspAndRelease'] as const;

export type FESName = (typeof FESGroup)[number];

export const PELVIC_CHANNEL_LIMIT = 2;

export const FES_CHANNEL_LIMIT = 3;

const emsStandardGroup: MuscleName[] = [
  'trapeziusAscendens',
  'trapeziusDescendes',
  'trapeziusTransversalis',
  'latissimusDorsi',
  'erectorSpinaeIliocostalis',
  'erectorSpinaeLongissimus',
  'extensorDigitorum',
  'flexorDigitorumSuperficialis',
  'flexorPollicisLongus',
  'pronatorTeres',
  'supraspinatus',
  'multifidus',
  'deltoideusAnterior',
  'deltoideusMedium',
  'deltoideusPosterior',
  //'iliopsoas', // TODO: in stella-app this is commented
  'infraspinatus',
  'bicepsBrachii',
  'tricepsBrachiiCLat',
  'tricepsBrachiiCLong',
  'brachioradialis',
  'flexorCarpiRadialis',
  'pectoralisMajor',
  'pectoralisMajor2',
  'serratusAnterior',
  'obliquusExternusAbdominis',
  'rectusAbdominis',
  'tensorFasciaLatae',
  'gluteusMaximus',
  'gluteusMedius',
  'adductores',
  'rectusFemoris',
  'vastusLateralis',
  'vastusMedialis',
  'bicepsFemoris',
  'semitendinosus',
  'gastrocnemiusMedialis',
  'gastrocnemiusCaputLaterale',
  'soleus',
  'peroneus',
  'tibialisAnterior',
];

const emsPelvicGroup: MuscleName[] = ['vagina', 'analMale', 'analFemale'];

const emsPainGroup: MuscleName[] = [
  'tensAnkle',
  'tensElbow',
  'tensFoot',
  'tensHand',
  'tensLowBack',
  'tensShoulder',
  'tensWrist',
  'tensUpperBack',
  'tensKnee',
  'tensHip',
  ...emsPelvicGroup,
];

const emgStandardGroup: MuscleName[] = [
  'frontalis',
  'sternocleidomastoid',
  'temporal',
  'masseter',
  'neckExtensors',
  'transversusAbdominis',
  ...emsStandardGroup,
];

const emgPelvicGroup: MuscleName[] = [
  'vagina',
  'analFemale',
  'analMale',
  'adductores',
  'gluteusMaximus',
  'rectusAbdominis',
  'transversusAbdominis',
];

export const allGroups = {
  ems: {
    fes: FESGroup,
    standard: emsStandardGroup,
    pelvic: emsPelvicGroup,
    pain: emsPainGroup,
  },
  emg: {
    standard: emgStandardGroup,
    pelvic: emgPelvicGroup,
  },
};

export function determineFESMuscles(exerciseName: EMSExerciseId | EMSEMGExerciseId): FESName {
  switch (exerciseName) {
    case 'fes_arm_extension_support':
    case 'emgt_arm_extension_support':
      return 'armSupport';
    case 'fes_hand_to_mouth':
    case 'emgt_hand_to_mouth':
      return 'handToMouth';
    case 'fes_grasp_and_release':
    case 'emgt_grasp_and_release':
    case 'fes_open_and_close':
    case 'emgt_open_and_close':
      return 'graspAndRelease';
    default:
      throw new Error(`Cannot determine muscle for given exercise: ${exerciseName}`);
  }
}

export function getMusclesForExercise(
  name: string,
  definition: EMGExerciseDefinition | EMSExerciseDefinition,
): (MuscleName | FESName)[] {
  if (definition.tags.includes('fes')) {
    return [determineFESMuscles(name as EMSExerciseId | EMSEMGExerciseId)];
  }
  if (definition.type === 'ems' && name.includes('tens')) {
    return allGroups.ems.pain;
  }
  if (
    'ems' in definition &&
    includesAllStrings(definition.ems.program.allowedCableChannelFeatures ?? [], ['ems', 'ems-pelvic'])
  ) {
    return [...allGroups.ems.standard, ...allGroups.ems.pelvic];
  }
  if ('ems' in definition && definition.ems.program.allowedCableChannelFeatures?.includes('ems-pelvic')) {
    return allGroups.ems.pelvic;
  }
  if (definition.type === 'ems' || definition.type === 'ems-emg') {
    return allGroups.ems.standard;
  }
  if (name === 'emg_glazer_protocol') {
    return allGroups.emg.pelvic;
  }
  if (
    'emg' in definition &&
    includesAllStrings(definition.emg.program.allowedCableChannelFeatures ?? [], ['emg', 'emg-pelvic'])
  ) {
    return [...allGroups.emg.standard, ...allGroups.emg.pelvic];
  }
  if (definition.type === 'emg' && !definition.emg.program.allowedCableChannelFeatures?.includes('emg-pelvic')) {
    return allGroups.emg.standard;
  }
  if (
    definition.type === 'emg-pelvic' ||
    (definition.type === 'emg' && definition.emg.program.allowedCableChannelFeatures?.includes('emg-pelvic'))
  ) {
    return allGroups.emg.pelvic;
  }

  return [...muscleNames, ...FESGroup];
}

export function getMuscleIdsByGroups(paths: (MuscleName | FESName)[], muscleIds: readonly MuscleId[]): MuscleId[] {
  return muscleIds.filter(muscleId => includesAnyString(muscleId, paths));
}
