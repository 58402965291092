import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from 'config/apiUrl';
import { getDateFromBackEnd } from 'helpers/formatDate';
import { redirectToLogin } from 'services/api';
import { exoClinicApi, exoClinicApiWithoutError } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

export const genderArray = [
  {
    value: 'male',
    label: 'gender.male',
  },
  {
    value: 'female',
    label: 'gender.female',
  },
  {
    value: 'unspecified',
    label: 'gender.unspecified',
  },
];

export type Gender = 'male' | 'female' | 'unspecified';

export interface UserState {
  userData?: ExoClinicBackendOpenApiSchemas['UserInfoResponseDto'];
  inProgress: boolean;
  isError: boolean;
  isLoaded: boolean;
  logoutInProgress: boolean;
  canAccessServiceMode: boolean;
}

const initialState: UserState = {
  userData: undefined,
  inProgress: false,
  isError: false,
  isLoaded: false,
  logoutInProgress: false,
  canAccessServiceMode: false,
};

const getUserDataFromPayload = (
  userPayload: ExoClinicBackendOpenApiSchemas['UserInfoResponseDto'],
): ExoClinicBackendOpenApiSchemas['UserInfoResponseDto'] => {
  const { userProfile } = userPayload;
  const { dateOfBirth } = userProfile;

  const details: ExoClinicBackendOpenApiSchemas['UserProfileInfoResponseDto'] = dateOfBirth
    ? {
        ...userProfile,
        dateOfBirth: getDateFromBackEnd(dateOfBirth).toJSON(),
      }
    : userProfile;

  return {
    ...userPayload,
    photo: userPayload.photo ? API_URL + 'files/' + userPayload.photo : '',
    userProfile: details,
  };
};

export const getCurrentUser = createAsyncThunk('users/current', (errorCallback?: () => void) =>
  exoClinicApiWithoutError.users.current().catch(() => {
    if (errorCallback && typeof errorCallback() === 'function') {
      errorCallback();
      return;
    }
  }),
);

export const logout = createAsyncThunk('auth/logout', () =>
  exoClinicApi.session.logout().then(() => {
    redirectToLogin();
  }),
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<ExoClinicBackendOpenApiSchemas['UserInfoResponseDto']>) => {
      state.userData = getUserDataFromPayload(action.payload);
      state.isLoaded = true;
      state.canAccessServiceMode = !!action.payload.isServiceAccount;
    },
    updateUserData: (state, action: PayloadAction<ExoClinicBackendOpenApiSchemas['UserProfileInfoResponseDto']>) => {
      if (state.userData) {
        state.userData.userProfile = action.payload;
      }
      state.isLoaded = true;
    },
    updateUserEmail: (state, action) => {
      const { userData } = state;
      if (userData) {
        userData.email = action.payload;
        state.isLoaded = true;
      }
    },
    updateUserName: (state, action) => {
      const { userData } = state;
      if (userData) {
        userData.userProfile.name = action.payload;
        state.isLoaded = true;
      }
    },
    updateUserSurname: (state, action) => {
      const { userData } = state;
      if (userData) {
        userData.userProfile.surname = action.payload;
        state.isLoaded = true;
      }
    },
    updateUserPhoto: (state, action: PayloadAction<string>) => {
      const { userData } = state;
      if (userData) {
        userData.photo = action.payload;
        state.isLoaded = true;
      }
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    authServiceMode: (state, action: PayloadAction<boolean>) => {
      state.canAccessServiceMode = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logout.pending, state => {
      state.logoutInProgress = true;
    });

    builder.addCase(logout.fulfilled, state => {
      state.userData = undefined;
      state = initialState;
    });

    builder.addCase(logout.rejected, state => {
      state.logoutInProgress = false;
    });

    builder.addCase(getCurrentUser.pending, state => {
      state.inProgress = true;
    });

    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      if (typeof action.payload === 'object') {
        state.userData = getUserDataFromPayload(action.payload);
        state.isLoaded = true;
      }
      state.inProgress = false;
    });

    builder.addCase(getCurrentUser.rejected, state => {
      state.inProgress = false;
      state.isLoaded = false;
    });
  },
});

export const {
  setUser,
  updateUserData,
  updateUserEmail,
  updateUserName,
  updateUserSurname,
  setLoaded,
  authServiceMode,
  updateUserPhoto,
} = userSlice.actions;

export default userSlice.reducer;
