import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSearchFromString } from 'helpers/search';
import { exoClinicApi } from 'services/ExoClinicBackendApi';
import { ExoClinicBackendOpenApiSchemas } from 'services/ExoClinicBackendOpenApi';

export enum PatientListFilterType {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export interface PatientListState {
  patientList: ExoClinicBackendOpenApiSchemas['PatientResponseDto'][];
  filter: PatientListFilterType;
  nextPage: number;
  isEndOfPages: boolean;
  search: string;
  inProgress: boolean;
  isError: boolean;
  isLoaded: boolean;
}

const initialState: PatientListState = {
  patientList: [],
  filter: PatientListFilterType.ACTIVE,
  search: '',
  nextPage: 0,
  isEndOfPages: false,
  inProgress: false,
  isError: false,
  isLoaded: false,
};

export interface GetPatientListParams {
  archived?: boolean;
  searchContent?: string;
  pageNumber?: number;
  pageSize: 10;
}

export interface GetPatientListArguments {
  facilityId: string;
  params?: GetPatientListParams;
}

export const getPatientList = createAsyncThunk('facilities/patients', async (arg: GetPatientListArguments) => {
  const { facilityId, params } = arg;
  const activeList = await exoClinicApi.facilities.facility(facilityId).patients({
    archived: params?.archived ?? false,
    pageNumber: params?.pageNumber ?? 0,
    pageSize: params?.pageSize ?? 0,
    searchContent: params?.searchContent ? getSearchFromString(params.searchContent) : [],
  });
  return activeList;
});

export const patientListSlice = createSlice({
  name: 'patientList',
  initialState,
  reducers: {
    setFilter: (state, { payload }: PayloadAction<PatientListFilterType>) => {
      state.filter = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPatientList.pending, state => {
      state.inProgress = true;
      state.isLoaded = false;
    });

    builder.addCase(getPatientList.fulfilled, (state, { payload }) => {
      state.inProgress = false;
      state.isLoaded = true;

      const { pageable, content, last } = payload;

      if (!state.patientList.length || !pageable?.pageNumber) {
        state.patientList = content;
        state.nextPage = 0;
      } else {
        content.forEach(payloadPatient => {
          const indexIfExist = state.patientList.findIndex(
            storePatient => storePatient.patientCardId === payloadPatient.patientCardId,
          );

          if (indexIfExist === -1) {
            state.patientList.push(payloadPatient);
            return;
          }

          state.patientList[indexIfExist] = payloadPatient;
        });
      }

      if (last) {
        state.isEndOfPages = true;
      } else {
        state.isEndOfPages = false;
        state.nextPage++;
      }
    });

    builder.addCase(getPatientList.rejected, state => {
      state.inProgress = false;
      state.isLoaded = true;
      state.isError = true;
    });
  },
});

export const { setFilter, setSearch } = patientListSlice.actions;

export default patientListSlice.reducer;
