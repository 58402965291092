import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { exoClinicApi, exoClinicApiWithoutError } from 'services/ExoClinicBackendApi';
import { UpdateAllResponseDTO, UpdateCurrentVersionResponseDTO, UpdateStatusResponseDTO } from 'types';

export type AppVersion = string;
export type AppSource = 'remote' | 'local' | 'firmware';

export type AvailableUpdate = {
  source: AppSource;
  version: AppVersion;
};

export type UpdateProgress = {
  progress: number;
  completed: boolean;
};

export type UpdateStep =
  | {
      step: 'question';
    }
  | {
      step: 'extensions-check';
    }
  | {
      step: 'initializing';
    }
  | ({
      step: 'progress';
    } & UpdateProgress)
  | {
      step: 'summary';
    };

export interface UpdateState {
  currentVersion?: AppVersion;
  availableUpdate?: AvailableUpdate;
  updateStep?: UpdateStep;

  status: {
    currentVersion: 'idle' | 'inprogress' | 'success' | 'error';
    versions: 'idle' | 'inprogress' | 'success' | 'error';
    updateStatus: 'idle' | 'inprogress' | 'success' | 'error';
    initializeUpdate: 'idle' | 'inprogress' | 'success' | 'error';
    initializeRestart: 'idle' | 'inprogress' | 'success' | 'error';
  };
}

export interface UpdatesState {
  firmware: UpdateState;
  software: UpdateState;
}

export type UpdatesKey = keyof UpdatesState;

const initialState: UpdatesState = {
  firmware: {
    status: {
      currentVersion: 'idle',
      versions: 'idle',
      updateStatus: 'idle',
      initializeUpdate: 'idle',
      initializeRestart: 'idle',
    },
    updateStep: {
      step: 'question',
    },
  },
  software: {
    status: {
      currentVersion: 'idle',
      versions: 'idle',
      updateStatus: 'idle',
      initializeUpdate: 'idle',
      initializeRestart: 'idle',
    },
  },
};

export const getCurrentVersion = createAsyncThunk('update/getCurrentVersion', async () => {
  const currentVersion = await exoClinicApi.device.update.current();

  return currentVersion as UpdateCurrentVersionResponseDTO;
});

export const getAvailableVersions = createAsyncThunk('update/getAvailableVersions', async () => {
  const availableVersions = {
    version: (await exoClinicApi.device.update.current()).version,
    available: await exoClinicApiWithoutError.device.update.available().catch(() => {
      return { version: null, source: null };
    }),
  };

  return availableVersions as UpdateAllResponseDTO;
});

export const checkUpdateStatus = createAsyncThunk('update/checkupdateStatus', async (id: UpdatesKey) => {
  const status =
    id === 'firmware' ? await exoClinicApi.device.update.firmware.status() : await exoClinicApi.device.update.status();

  return status as UpdateStatusResponseDTO;
});

export const initializeUpdate = createAsyncThunk('update/initializeUpdate', async (id: UpdatesKey) => {
  if (id === 'software') {
    await exoClinicApi.device.update.update();
  } else if (id === 'firmware') {
    await exoClinicApi.device.update.firmware.update();
  }
});

export const initializeRestart = createAsyncThunk('update/initializeRestart', async (id: UpdatesKey) => {
  if (id === 'software') {
    await exoClinicApi.device.update.restart();
  } else if (id === 'firmware') {
    await exoClinicApi.device.update.firmware.restart();
  }
});

const updateSlice = createSlice({
  name: 'update',
  initialState,
  reducers: {
    setUpdateSoftwareStep: (state, action: PayloadAction<UpdateState['updateStep']>) => {
      state.software.updateStep = action.payload;
    },
    setUpdateFirmwareStep: (state, action: PayloadAction<UpdateState['updateStep']>) => {
      state.firmware.updateStep = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCurrentVersion.pending, state => {
      state.software.status.currentVersion = 'inprogress';
    });

    builder.addCase(getCurrentVersion.fulfilled, (state, { payload }) => {
      state.software.status.currentVersion = 'success';
      state.software.currentVersion = payload.version;
    });
    builder.addCase(getCurrentVersion.rejected, state => {
      state.software.status.currentVersion = 'error';
    });

    builder.addCase(getAvailableVersions.pending, state => {
      state.software.status.versions = 'inprogress';
    });

    builder.addCase(getAvailableVersions.fulfilled, (state, { payload }) => {
      state.software.status.versions = 'success';
      state.software.currentVersion = payload.version;
      if (
        payload.available?.version !== state.software.availableUpdate?.version ||
        payload.available?.source !== state.software.availableUpdate?.source
      ) {
        state.software.availableUpdate = payload.available;
      }
    });
    builder.addCase(getAvailableVersions.rejected, state => {
      state.software.status.versions = 'error';
    });

    builder.addCase(checkUpdateStatus.pending, (state, { meta: { arg } }) => {
      state[arg].status.updateStatus = 'inprogress';
    });
    builder.addCase(checkUpdateStatus.fulfilled, (state, { payload, meta: { arg } }) => {
      state[arg].updateStep = {
        step: 'progress',
        progress: payload.progress,
        completed: payload.completed,
      };
      state[arg].status.updateStatus = 'success';
    });
    builder.addCase(checkUpdateStatus.rejected, (state, { meta: { arg } }) => {
      state[arg].status.updateStatus = 'error';
    });

    builder.addCase(initializeUpdate.pending, (state, { meta: { arg } }) => {
      state[arg].updateStep = {
        step: 'initializing',
      };

      state[arg].status.initializeUpdate = 'inprogress';
    });

    builder.addCase(initializeUpdate.fulfilled, (state, { meta: { arg } }) => {
      state[arg].updateStep = {
        step: 'progress',
        progress: 0,
        completed: false,
      };
      state[arg].status.initializeUpdate = 'success';
    });
    builder.addCase(initializeUpdate.rejected, (state, { meta: { arg } }) => {
      state[arg].updateStep = {
        step: 'question',
      };

      state[arg].status.initializeUpdate = 'error';
    });

    builder.addCase(initializeRestart.pending, (state, { meta: { arg } }) => {
      state[arg].status.initializeRestart = 'inprogress';
    });
    builder.addCase(initializeRestart.fulfilled, (state, { meta: { arg } }) => {
      state[arg].status.initializeRestart = 'success';
    });
    builder.addCase(initializeRestart.rejected, (state, { meta: { arg } }) => {
      state[arg].status.initializeRestart = 'error';
    });
  },
});

export const { setUpdateSoftwareStep, setUpdateFirmwareStep } = updateSlice.actions;

export default updateSlice.reducer;
